import { createSelector } from 'reselect';
import { chunk, last } from 'lodash';
import { addMilliseconds, differenceInMilliseconds, formatISO, parseISO } from 'date-fns';
import { conversationSelectors } from '../conversations';
import messagesSelectors from '../messages/selectors';

const getSession = (state) => state.session;

const getIns = (state) => getSession(state).in;

const getOuts = (state) => getSession(state).out;

const getCumulativeTime = (state) => getSession(state).cumulativeTime;

const getActiveDialogs = (state) => getSession(state).activeDialogs;

const getPage = (state) => getSession(state).page;

const getReservedOnce = (state) => getSession(state).reservedOnce;

const getMeanTime = createSelector(getOuts, getCumulativeTime, (number, time) =>
  number !== 0 ? (time / number).toFixed(0) : 0
);

const getWorkHours = (state) => getSession(state).workTime;

const getPauses = (state) => getSession(state).pauses;

const getIsPaused = createSelector(getPauses, (pauses) => pauses.length % 2 === 1);
const getPauseTime = createSelector(getPauses, getIsPaused, (pauses, isPaused) =>
  isPaused ? last(pauses) : null
);

const getCumulativePauseTime = createSelector(getPauses, (pauses) =>
  chunk(pauses, 2).reduce(
    (duration, [start, end]) =>
      end ? duration + differenceInMilliseconds(parseISO(end), parseISO(start)) : duration,
    0
  )
);

const getRelativeWorkStart = createSelector(
  getWorkHours,
  getCumulativePauseTime,
  (workStart, pauseDuration) =>
    formatISO(addMilliseconds(workStart ? parseISO(workStart) : new Date(), pauseDuration ?? 0))
);

const getActiveDialogConversations = createSelector(
  conversationSelectors.getConversationsWithUsers,
  getActiveDialogs,
  messagesSelectors.getLastMessageForConversations,
  (conversations, ids, lastMessages) =>
    ids.map((item) => ({ ...item, ...conversations[item.id], message: lastMessages[item.id] }))
);

const getActiveDialogCount = createSelector(
  getActiveDialogs,
  (activeDialogs) => activeDialogs.length
);

export default {
  getIns,
  getOuts,
  getMeanTime,
  getRelativeWorkStart,
  getPauseTime,
  getPage,
  getIsPaused,
  getActiveDialogConversations,
  getActiveDialogCount,
  getReservedOnce,
};
