import types from './types';

const getConfiguration = () => ({ type: types.getConfiguration });

const getConfigurationSuccess = configuration => ({
  type: types.getConfigurationSuccess,
  configuration,
});
const getConfigurationFailure = error => ({ type: types.getConfigurationFailure, error });

export default {
  getConfiguration,
  getConfigurationSuccess,
  getConfigurationFailure,
};
