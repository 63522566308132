const add = 'site/restroom/ADD';
const addSuccess = 'site/restroom/ADDSUCCESS';
const addFailure = 'site/restroom/ADDFAILURE';
const addIncoming = 'site/restroom/ADDINCOMING';
const remove = 'site/restroom/REMOVE';
const getRestroom = 'site/restroom/GET';
const getRestroomSuccess = 'site/restroom/GETSUCCESS';
const getRestroomFailure = 'site/restroom/GETFAILURE';
const flushSuccess = 'site/restroom/FLUSHSUCCESS';
const flushFailure = 'site/restroom/FLUSHFAILURE';

export default {
  add,
  addSuccess,
  addFailure,
  addIncoming,
  getRestroom,
  getRestroomSuccess,
  getRestroomFailure,
  remove,
  flushSuccess,
  flushFailure,
};
