import { combineReducers } from 'redux';
import { uniqBy } from 'lodash';
import types from './types';
import agentTypes from '../agent/types';
import reservedConversationTypes from '../reservedConversation/types';
import { websocketTypes } from '../websocket';

const inReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.sessionIn:
      return state + 1;
    default:
      return state;
  }
};

const outReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case reservedConversationTypes.holdMessageSuccess:
    case reservedConversationTypes.sendMessageSuccess:
      return state + 1;
    default:
      return state;
  }
};

const cumulativeTimeReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case reservedConversationTypes.holdMessageSuccess:
    case reservedConversationTypes.sendMessageSuccess: {
      const { usedTime } = action;
      return state + usedTime;
    }
    default:
      return state;
  }
};

const workTimeReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case agentTypes.loginSuccess:
      return action.startTime;
    case agentTypes.logoutSuccess:
      return null;
    default:
      return state;
  }
};

const pauseReducer = (state = [], action) => {
  switch (action.type) {
    case agentTypes.loginSuccess:
      return [];
    case types.togglePause:
      return [...state, action.time];
    default:
      return state;
  }
};

const activeDialogsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case reservedConversationTypes.sendMessage: {
      const { id, queueName } = action;
      return uniqBy([...state, { id, queueName }], 'id');
    }
    default:
      return state;
  }
};

const pageReducer = (state = null, action) => {
  switch (action.type) {
    case types.changePage:
      return action.page;
    default:
      return state;
  }
};

const reservedOnceReducer = (state = false, action) => {
  switch (action.type) {
    case types.hasReservedOnce:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  in: inReducer,
  out: outReducer,
  cumulativeTime: cumulativeTimeReducer,
  activeDialogs: activeDialogsReducer,
  workTime: workTimeReducer,
  pauses: pauseReducer,
  page: pageReducer,
  reservedOnce: reservedOnceReducer,
});
