const connected = 'site/websocket/CONNECTED';
const disconnected = 'site/websocket/DISCONNECTED';
const sendMessage = 'site/websocket/SEND';
const receiveMessage = 'site/websocket/RECEIVE';
const incoming = 'site/websocket/INCOMING';
const restroom = 'site/websocket/RESTROOM';
const deleteCon = 'site/websocket/DELETECON';
const flush = 'site/websocket/FLUSH';
const sessionIn = 'site/websocket/SESSIONIN';
const create = 'site/websocket/CREATE';
const reserve = 'site/websocket/RESERVE';

export default {
  connected,
  disconnected,
  sendMessage,
  receiveMessage,
  incoming,
  deleteCon,
  restroom,
  flush,
  sessionIn,
  create,
  reserve,
};
