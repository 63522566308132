const getPresents = 'site/presents/GET';
const getPresentsSuccess = 'site/presents/GETSUCCESS';
const getPresentsFailure = 'site/presents/GETFAILURE';
const sendPresent = 'site/messages/SENDPRESENT';
const sendPresentSuccess = 'site/messages/SENDPRESENTSUCCESS';
const sendPresentFailure = 'site/messages/SENDPRESENTFAILURE';

export default {
  getPresents,
  getPresentsSuccess,
  getPresentsFailure,
  sendPresent,
  sendPresentSuccess,
  sendPresentFailure,
};
