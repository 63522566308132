import { formatISO } from 'date-fns';
import types from './types';

const getProfile = (userId, conversationId) => ({
  type: types.loadProfile,
  userId,
  conversationId,
});
const getProfileSuccess = (userId, profile) => ({
  type: types.loadProfileSuccess,
  userId,
  profile,
});
const getProfileFailure = (error) => ({
  type: types.loadProfileFailure,
  error,
});

const visitProfile = (id, asUser, conversationId) => ({
  type: types.visitProfile,
  id,
  asUser,
  conversationId,
});

const visitProfileSuccess = (conversationId, userId) => ({
  type: types.visitProfileSuccess,
  conversationId,
  userId,
  timestamp: formatISO(new Date()),
});

const visitProfileFailure = (error) => ({
  type: types.visitProfileFailure,
  error,
});

const reportProfile = (id, conversationId, reason, flags) => ({
  type: types.reportProfile,
  id,
  conversationId,
  reason,
  flags,
});

const reportProfileSuccess = (id) => ({ type: types.reportProfileSuccess, id });

const reportProfileFailure = (error) => ({
  type: types.reportProfileFailure,
  error,
});

const updateProfile = (id, profile) => ({
  type: types.updateProfile,
  id,
  profile,
});

const updateProfileSuccess = (id, profile) => ({
  type: types.updateProfileSuccess,
  id,
  profile,
});

const updateProfileFailure = (error) => ({
  type: types.updateProfileFailure,
  error,
});

const setStatement = (id, statement) => ({
  type: types.setStatement,
  id,
  statement,
});

const setStatementSuccess = (id, statement) => ({
  type: types.setStatementSuccess,
  id,
  statement,
});

const setStatementFailure = (error) => ({
  type: types.setStatementFailure,
  error,
});

export default {
  getProfile,
  getProfileSuccess,
  getProfileFailure,
  visitProfile,
  visitProfileSuccess,
  visitProfileFailure,
  reportProfile,
  reportProfileSuccess,
  reportProfileFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  setStatement,
  setStatementSuccess,
  setStatementFailure,
};
