import types from './types';

const configurationReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.getConfigurationSuccess: {
      const { configuration } = action;
      return configuration;
    }
    default:
      return state;
  }
};

export default configurationReducer;
