import { createSelector } from 'reselect';
import { reverse, sortBy, mapValues, groupBy, maxBy } from 'lodash';
import { parseISO } from 'date-fns';
import { usersSelectors } from '../users';

const getAllMessageIds = ({ messages: { allIds } }) => allIds;

const getMessagesById = ({ messages: { byId } }) => byId;

const getIsReporting = ({ messages: { isReporting } }) => isReporting;

const getConversationId = (_, { id }) => id;

const makeGetEmptyConversation = (id) =>
  createSelector(
    getAllMessageIds,
    getMessagesById,
    (ids, items) =>
      !ids.reduce((bool, messageId) => items[messageId].conversationId === id || bool, false)
  );

const makeGetMessagesForConversation = () =>
  createSelector(
    getAllMessageIds,
    getMessagesById,
    getConversationId,
    usersSelectors.getUsersById,
    (ids, items, convoId, users) =>
      reverse(
        sortBy(
          ids.reduce(
            (arr, id) =>
              items[id].conversationId === convoId
                ? [...arr, { ...items[id], user: users[items[id].userId] }]
                : arr,
            []
          ),
          ({ createdAt }) => parseISO(createdAt).getTime()
        )
      )
  );

const getLastMessageForConversations = createSelector(
  getAllMessageIds,
  getMessagesById,
  (ids, messages) =>
    mapValues(
      groupBy(
        ids.map((id) => messages[id]),
        'conversationId'
      ),
      (valCollection) => {
        const lastMessage = maxBy(
          valCollection.filter(({ messageType, createdAt }) => messageType === 'Text' && createdAt),
          'createdAt'
        );
        return lastMessage || undefined;
      }
    )
);

export default {
  makeGetMessagesForConversation,
  makeGetEmptyConversation,
  getLastMessageForConversations,
  getIsReporting,
};
