import { createSelector } from 'reselect';

const getConfiguration = ({ configuration }) => configuration;

const getMessageConfig = createSelector(
  getConfiguration,
  (configuration) => configuration && configuration.messages
);

const getWorkOrder = createSelector(
  getConfiguration,
  (configuration) => configuration && configuration.workOrder
);

const getConnections = createSelector(
  getConfiguration,
  (configuration) => configuration && configuration.connections
);

const getAuth = createSelector(
  getConfiguration,
  (configuration) => configuration && configuration.auth
);
const getAsaConfig = createSelector(getConfiguration, (configuration) =>
  configuration ? configuration.asa : {}
);

const getPrivileges = createSelector(getConfiguration, (configuration) =>
  configuration ? configuration.privileges : {}
);

const getVisitConfig = createSelector(getConfiguration, (configuration) =>
  configuration ? configuration.visit : {}
);

const getStatementConfig = createSelector(
  getConfiguration,
  (configuration) => configuration?.availableStatements ?? []
);

const getLanguages = createSelector(
  getConfiguration,
  (configuration) => configuration?.languages ?? []
);

const getCanChangeLanguage = createSelector(
  getConfiguration,
  (configuration) => !!configuration?.canChangeLanguage
);

export default {
  getConnections,
  getMessageConfig,
  getWorkOrder,
  getAuth,
  getConfiguration,
  getAsaConfig,
  getPrivileges,
  getVisitConfig,
  getStatementConfig,
  getLanguages,
  getCanChangeLanguage,
};
