import { formatISO } from 'date-fns';
import types from './types';

const addIn = () => ({ type: types.addIn });
const togglePause = () => ({ type: types.togglePause, time: formatISO(new Date()) });
const pauseError = (error) => ({ type: types.pauseError, error });
const changePage = (page) => ({ type: types.changePage, page });
const changePageSuccess = () => ({ type: types.changePageSuccess });
const changePageFailure = (error) => ({ type: types.changePageFailure, error });
const hasReservedOnce = () => ({ type: types.hasReservedOnce });

export default {
  addIn,
  togglePause,
  pauseError,
  changePage,
  changePageSuccess,
  changePageFailure,
  hasReservedOnce,
};
