import { formatISO } from 'date-fns';
import types from './types';

const reserveNextConversation = (isIncoming = false) => ({
  type: types.reserveNextConversation,
  isIncoming,
});
const reserveConversation = (
  id,
  queueName,
  asaLevel,
  origin = 'queue',
  isIncoming = false,
  timeZone,
  language
) => ({
  type: types.reserveConversation,
  id,
  queueName,
  asaLevel,
  fromRestroom: origin === 'restroom',
  fromOutOfQueue: origin === 'restroom' || origin === 'activeDialogs',
  isIncoming,
  timeZone,
  language,
});
const reserveConversationSuccess = (
  id,
  queueName,
  asaLevel,
  isIncoming = false,
  timeZone,
  language
) => ({
  type: types.reserveConversationSuccess,
  id,
  queueName,
  asaLevel,
  isIncoming,
  timeZone,
  language,
  startTime: formatISO(new Date()),
});
const reserveConversationFailure = (error, id) => ({
  type: types.reserveConversationFailure,
  id,
  error,
});
const freezeConversation = (id, duration, reason) => ({
  type: types.freezeConversation,
  id,
  duration,
  reason,
});
const freezeConversationSuccess = () => ({ type: types.freezeConversationSuccess });
const freezeConversationFailure = (error) => ({ type: types.freezeConversationFailure, error });
const sendMessage = (id, text, hold = false, freezeDuration, reason) => ({
  type: types.sendMessage,
  id,
  text,
  hold,
  freezeDuration,
  reason,
});
const holdMessageSuccess = (id, message, usedTime) => ({
  type: types.holdMessageSuccess,
  id,
  message,
  usedTime,
  startTime: formatISO(new Date()),
});
const sendMessageSuccess = (id, message, usedTime) => ({
  type: types.sendMessageSuccess,
  id,
  message,
  usedTime,
});
const sendMessageFailure = (error) => ({ type: types.sendMessageFailure, error });
const handOver = (id, newLanguage) => ({ type: types.handOver, id, newLanguage });

const handOverSuccess = () => ({ type: types.handOverSuccess });

const handOverFailure = (error) => ({ type: types.handOverFailure, error });

const emptyQueue = () => ({ type: types.emptyQueue });
const discardConversation = (id, withRouting = true) => ({
  type: types.discardConversation,
  id,
  withRouting,
});
const clearFailure = () => ({ type: types.clearFailure });
const deleted = (id) => ({ type: types.deleted, id });
const takePrereservedConversation = (id, queueName, asaLevel, timeZone, language) => ({
  type: types.takePrereservedConversation,
  id,
  queueName,
  asaLevel,
  timeZone,
  language,
  startTime: formatISO(new Date()),
});

export default {
  reserveNextConversation,
  reserveConversation,
  reserveConversationSuccess,
  reserveConversationFailure,
  freezeConversation,
  freezeConversationSuccess,
  freezeConversationFailure,
  sendMessage,
  holdMessageSuccess,
  sendMessageSuccess,
  sendMessageFailure,
  handOver,
  handOverSuccess,
  handOverFailure,
  emptyQueue,
  discardConversation,
  clearFailure,
  deleted,
  takePrereservedConversation,
};
