import { combineReducers } from 'redux';
import { uniq } from 'lodash';
import types from './types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.loadProfileSuccess: {
      const { userId } = action;
      return uniq([...state, userId]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loadProfileSuccess: {
      const { userId, profile } = action;
      return { ...state, [userId]: profile };
    }
    case types.updateProfileSuccess: {
      const { id, profile } = action;
      if (state[id]) {
        return { ...state, [id]: { ...state[id], ...profile } };
      }
      return state;
    }
    case types.setStatementSuccess: {
      const { id, statement } = action;
      if (state[id]) {
        return { ...state, [id]: { ...state[id], statement } };
      }
      return state;
    }
    default:
      return state;
  }
};

const isVisitingReducer = (state = false, action) => {
  switch (action.type) {
    case types.visitProfile:
      return true;
    case types.visitProfileSuccess:
    case types.visitProfileFailure:
      return false;
    default:
      return state;
  }
};

const isReportingReducer = (state = false, action) => {
  switch (action.type) {
    case types.reportProfile:
      return true;
    case types.reportProfileSuccess:
    case types.reportProfileFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer,
  isVisiting: isVisitingReducer,
  isReporting: isReportingReducer,
});
