import types from './types';

const createQueue = (name) => ({
  type: types.createQueues,
  name,
});
const retrieveQueue = (name) => ({
  type: types.retrieveQueues,
  name,
});
const retrieveQueueSuccess = (name, conversations) => ({
  type: types.retrieveQueuesSuccess,
  name,
  conversations,
});
const retrieveQueueFailure = (name, error) => ({
  type: types.retrieveQueuesFailure,
  name,
  error,
});
const addToQueue = (name, conversation) => ({
  type: types.addToQueue,
  name,
  conversation,
});
const removeFromQueue = (name, id) => ({
  type: types.removeFromQueue,
  name,
  id,
});
const setWorkOrder = (order) => ({ type: types.setOrder, order });

const loaded = () => ({ type: types.loaded });

const flush = (name, timeZone) => ({ type: types.flush, name, timeZone });

const resetConversationLoad = () => ({ type: types.resetConversationLoad });

const load = () => ({ type: types.load });

export default {
  createQueue,
  retrieveQueue,
  retrieveQueueSuccess,
  retrieveQueueFailure,
  addToQueue,
  removeFromQueue,
  setWorkOrder,
  loaded,
  flush,
  resetConversationLoad,
  load,
};
