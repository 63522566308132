import uuid from 'uuid/v4';
import { formatISO } from 'date-fns';
import types from './types';

const connected = (connection) => ({ type: types.connected, connection });
const disconnected = () => ({ type: types.disconnected });
const sendMessage = (queueName, action, data) => ({
  type: types.sendMessage,
  transactionId: uuid(),
  queueName,
  action,
  data,
});
const receiveMessage = (queueName, action, data) => ({
  type: types.receiveMessage,
  queueName,
  action,
  data,
  time: formatISO(new Date()),
});

const incoming = (message) => ({
  type: types.incoming,
  message,
});

const restroom = (conversation, reason, queueName) => ({
  type: types.restroom,
  conversation,
  reason,
  queueName,
  inRestroomSince: formatISO(new Date()),
});

const deleteCon = (queueName, id) => ({
  type: types.deleteCon,
  id,
  queueName,
});

const flush = (queueName, timeZone) => ({
  type: types.flush,
  queueName,
  timeZone,
});

const sessionIn = () => ({
  type: types.sessionIn,
});

const create = (conversation, queueName) => ({
  type: types.create,
  conversation,
  queueName,
});

const reserve = (queueName, id) => ({
  type: types.reserve,
  queueName,
  id,
});

export default {
  connected,
  disconnected,
  sendMessage,
  receiveMessage,
  incoming,
  restroom,
  deleteCon,
  flush,
  sessionIn,
  create,
  reserve,
};
