import { channel } from 'redux-saga';
import { put, call, takeEvery, fork, actionChannel, take, select } from 'redux-saga/effects';
import { chunk, uniqBy, flatMap } from 'lodash';
import {
  ShowConversation,
  GetConversationStatistics,
  GetConversationsById,
} from '../services/ModerationApi';
import actions from './actions';
import types from './types';
import { usersActions } from '../users';
import selectors from './selectors';

function* getConversation({ id }) {
  try {
    const { messages, conversation } = yield call(ShowConversation, id);
    yield put(actions.getConversationSuccess(id, messages, conversation));
  } catch (error) {
    yield put(actions.getConversationFailure(id, error));
  }
}

function* handleGetConversation() {
  yield takeEvery(types.getConversation, getConversation);
}

function* loadMoreMessages({ id, page }) {
  try {
    const {
      conversation: { moreRecords },
      messages,
    } = yield call(ShowConversation, id, page);
    yield put(actions.loadMoreSuccess(id, messages, moreRecords));
  } catch (error) {
    yield put(actions.loadMoreFailure(id, error));
  }
}

function* handleLoadMore() {
  const taskList = {};
  while (true) {
    const action = yield take(types.loadMore);
    const { id } = action;
    if (!taskList[id] || !taskList[id].isRunning()) {
      taskList[id] = yield fork(loadMoreMessages, action);
    }
  }
}

function* getStatistics({ id }) {
  try {
    const statistics = yield call(GetConversationStatistics, id);
    yield put(actions.getStatisticsSuccess(id, statistics));
  } catch (error) {
    yield put(actions.getStatisticsFailure(id, error));
  }
}

function* handleGetStatistics() {
  yield takeEvery(types.getStatistics, getStatistics);
}

function* loadConversations(ids) {
  try {
    const conversations = yield call(GetConversationsById, ids);
    const users = uniqBy(flatMap(conversations, 'users'), 'id');
    yield put(usersActions.addUsers(users));
    const newConversations = conversations.map(conversation => ({
      ...conversation,
      users: conversation.users.map(({ id }) => id),
    }));
    yield put(actions.addConversations(newConversations));
  } catch (error) {
    yield put(actions.loadConversationsFailure(error));
  }
}

function* takeIdsFromChannel(takeChannel) {
  while (true) {
    const ids = yield take(takeChannel);
    yield call(loadConversations, ids);
  }
}

const concurrentlyOpenRequests = 2;

const idChunkSize = 5;

function* handleAddConversationsById() {
  const bufferChannel = yield call(channel);
  for (let i = 0; i < concurrentlyOpenRequests; i += 1) {
    yield fork(takeIdsFromChannel, bufferChannel);
  }
  const requestChannel = yield actionChannel(types.addConversationsById);
  while (true) {
    const { ids } = yield take(requestChannel);
    const knownIds = yield select(selectors.getAllConversationIds);
    const idChunks = chunk(
      ids.filter(id => !knownIds.some(item => id === item)),
      idChunkSize
    );
    for (let i = 0; i < idChunks.length; i += 1) {
      yield put(bufferChannel, idChunks[i]);
    }
  }
}

export default {
  handleGetConversation,
  handleLoadMore,
  handleGetStatistics,
  handleAddConversationsById,
};
