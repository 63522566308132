import { put, takeLatest, call, fork, takeEvery, select } from 'redux-saga/effects';
import { flatMap, uniqBy } from 'lodash';
import actions from './actions';
import types from './types';
import { websocketTypes, websocketActions } from '../websocket';
import { GetRestroom, ToRestroom, FlushFromRestroom } from '../services/ModerationApi';
import { conversationActions } from '../conversations';
import { usersActions } from '../users';
import selectors from './selectors';
import { agentSelectors } from '../agent';

function* getRestRooms() {
  try {
    const conversations = yield call(GetRestroom);
    yield put(actions.getRestroomSuccess(conversations));
  } catch (error) {
    yield put(actions.getRestroomFailure(error));
  }
}

function* handleGetRestroom() {
  yield takeLatest(websocketTypes.connected, getRestRooms);
}

function* addToRestroom({ conversation, queueName, reason }) {
  yield put(
    websocketActions.sendMessage(queueName, 'restroom', {
      conversation,
      reason,
    })
  );
  const { id, timeZone, language } = conversation;
  try {
    yield call(ToRestroom, id, reason);
    yield put(actions.addSuccess(id, reason, queueName, timeZone, language));
  } catch (error) {
    yield put(actions.addFailure(id, error));
  }
}

function* handleAddToRestRoom() {
  yield takeLatest(types.add, addToRestroom);
}

function* getConversations({ conversations }) {
  const users = uniqBy(
    flatMap(conversations, ({ users }) => users),
    'id'
  );
  yield put(usersActions.addUsers(users));
  yield put(
    conversationActions.addConversations(
      conversations.map((conversation) => ({
        ...conversation,
        users: conversation.users.map(({ id }) => id),
      }))
    )
  );
}

function* handleGetConversations() {
  yield takeLatest(types.getRestroomSuccess, getConversations);
}

function* flushFromRestroom({ queueName, timeZone }) {
  const items = yield select(selectors.makeGetItemsFromQueue(queueName));
  console.log('items', items);
  try {
    const myIds = items.reduce(
      (arr, item) =>
        item.myItem && (!timeZone || timeZone === item.timeZone) ? [...arr, item.id] : arr,
      []
    );
    if (myIds.length) {
      yield call(FlushFromRestroom, myIds);
    }
    yield put(actions.flushSuccess(items.map((item) => item.id)));
  } catch (error) {
    yield put(actions.flushFailure(error));
  }
}

function* handleReceiveMessage() {
  yield takeEvery(websocketTypes.flush, flushFromRestroom);
}

export default {
  handleGetConversations,
  handleGetRestroom,
  handleAddToRestRoom,
  handleReceiveMessage,
};
