import { combineReducers } from 'redux';
import { uniq, uniqBy, mapValues } from 'lodash';
import types from './types';
import restroomTypes from '../restroom/types';
import reservedConversationTypes from '../reservedConversation/types';
import { prereservedConversationTypes } from '../prereservedConversations';
import websocketTypes from '../websocket/types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.createQueues: {
      const { name } = action;
      return uniq([...state, name]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type, name } = action;
  switch (type) {
    case types.createQueues:
      return {
        ...state,
        [action.name]: {
          name,
          items: [],
          loaded: false,
        },
      };

    case types.retrieveQueuesSuccess:
    case types.retrieveQueuesFailure:
      if (state[action.name]) {
        return {
          ...state,
          [action.name]: valueReducer(state[action.name], action),
        };
      }
      return state;
    case websocketTypes.deleteCon:
    case websocketTypes.reserve:
    case websocketTypes.create:
    case websocketTypes.flush:
    case reservedConversationTypes.deleted:
    case restroomTypes.addSuccess:
    case prereservedConversationTypes.prereserveConversation:
    case reservedConversationTypes.reserveConversation: {
      const { queueName } = action;
      if (state[queueName]) {
        return {
          ...state,
          [queueName]: valueReducer(state[queueName], action),
        };
      }
      return state;
    }
    case restroomTypes.addIncoming:
      return mapValues(state, (value) => valueReducer(value, action));
    default:
      return state;
  }
};

const valueReducer = (state, action) => {
  const { type } = action;
  const { items } = state;
  switch (type) {
    case websocketTypes.create: {
      const {
        conversation: { id, inQueueSince, level, timeZone, language },
      } = action;
      return {
        ...state,
        items: uniqBy(
          [...items, { id, inQueueSince, level, from: 'websocket', timeZone, language }],
          'id'
        ),
      };
    }
    case prereservedConversationTypes.prereserveConversation:
    case reservedConversationTypes.reserveConversation:
    case reservedConversationTypes.deleted:
    case websocketTypes.reserve:
    case websocketTypes.deleteCon: {
      const { id } = action;
      return {
        ...state,
        items: items.filter(({ id: convId }) => convId !== id),
      };
    }
    case types.retrieveQueuesSuccess: {
      const { conversations } = action;
      return {
        ...state,
        loaded: true,
        items: conversations.map(({ id, inQueueSince, level, timeZone, language }) => ({
          id,
          inQueueSince,
          level,
          timeZone,
          language,
          from: 'preload',
        })),
      };
    }
    case restroomTypes.addIncoming:
    case restroomTypes.addSuccess: {
      const { id } = action;
      return {
        ...state,
        items: items.filter(({ id: convId }) => convId !== id),
      };
    }
    case types.retrieveQueuesFailure: {
      return { ...state, loaded: true };
    }
    case websocketTypes.flush: {
      return {
        ...state,
        items: state.items.filter(
          ({ timeZone }) => !action.timeZone && action.timeZone !== timeZone
        ),
      };
    }
    default:
      return state;
  }
};

const orderReducer = (state = [], action) => {
  const { type, order } = action;
  switch (type) {
    case types.setOrder: {
      return order;
    }
    default:
      return state;
  }
};

const loadedReducer = (state = true, action) => {
  switch (action.type) {
    case types.loaded:
      return true;
    case types.load:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer,
  order: orderReducer,
  loaded: loadedReducer,
});
