import { combineReducers } from 'redux';
import types from './types';

const agentReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loginSuccess: {
      const { agent } = action;
      return agent;
    }
    case types.logoutSuccess:
      return {};
    default:
      return state;
  }
};

const teamReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loginSuccess: {
      const { team } = action;
      return team;
    }
    case types.logoutSuccess:
      return {};
    default:
      return state;
  }
};

const agencyReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loginSuccess: {
      const { agency } = action;
      return agency;
    }
    case types.logoutSuccess:
      return {};
    default:
      return state;
  }
};

const tokenReducer = (state = null, action) => {
  const { type, token } = action;
  switch (type) {
    case types.loginSuccess:
      return token;
    case types.logoutSuccess:
      return null;
    default:
      return state;
  }
};

const languageReducer = (state = null, action) => {
  switch (action.type) {
    case types.loginSuccess:
      return action.language;
    case types.logoutSuccess:
      return null;
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.login:
      return true;
    case types.loginSuccess:
    case types.loginFailure:
      return false;
    default:
      return state;
  }
};

const passwordStateReducer = (state = 'unchanged', action) => {
  const { type } = action;
  switch (type) {
    case types.changePassword:
      return 'changing';
    case types.changePasswordSuccess:
      return 'changed';
    case types.changePasswordFailure:
      return 'failure';
    default:
      return state;
  }
};

const mustChangePasswordReducer = (state = false, action) => {
  const { type, mustChangePassword } = action;
  switch (type) {
    case types.loginSuccess:
      return mustChangePassword;
    case types.changePasswordSuccess:
      return false;
    default:
      return state;
  }
};

const acceptedTosReducer = (state = true, action) => {
  const { type, acceptedTos } = action;
  switch (type) {
    case types.loginSuccess:
      return acceptedTos;
    case types.acceptTosSuccess:
      return true;
    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  switch (action.type) {
    case types.loginFailure:
      return action.error;
    case types.loginSuccess:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  agent: agentReducer,
  team: teamReducer,
  agency: agencyReducer,
  token: tokenReducer,
  isLoading: loadingReducer,
  passwordState: passwordStateReducer,
  mustChangePassword: mustChangePasswordReducer,
  error: errorReducer,
  acceptedTos: acceptedTosReducer,
  language: languageReducer,
});
