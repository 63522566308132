import { combineReducers } from 'redux';
import { omit } from 'lodash';
import types from './types';
import reservedConversationTypes from '../reservedConversation/types';
import websocketTypes from '../websocket/types';

const sortedIdsReducer = (state = [], action) => {
  switch (action.type) {
    case types.prereserveConversationSuccess:
      return [...state, action.id];
    case reservedConversationTypes.takePrereservedConversation:
    case types.discardReservedConversationSuccess:
    case types.discardReservedConversationFailure:
    case reservedConversationTypes.deleted:
    case websocketTypes.deleteCon:
      return state.filter((item) => item !== action.id);
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.prereserveConversationSuccess: {
      const { id, queueName, reserveTime, asaLevel, timeZone, language } = action;
      return {
        ...state,
        [id]: {
          id,
          queueName,
          reserveTime,
          asaLevel,
          timeZone,
          language,
        },
      };
    }
    case reservedConversationTypes.deleted:
    case reservedConversationTypes.takePrereservedConversation:
    case types.discardReservedConversationFailure:
    case types.discardReservedConversationSuccess:
    case websocketTypes.deleteCon:
      return omit(state, action.id);
    default:
      return state;
  }
};

export default combineReducers({
  sortedIds: sortedIdsReducer,
  byId: byIdReducer,
});
