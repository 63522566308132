import { uniq, omit, keyBy, difference } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import reservedConversationTypes from '../reservedConversation/types';
// Hier wurde zyklisch geladen, Referenzauflösung notwendig damit korrekt angezeigt
import queuesTypes from '../queues/types';
import websocketTypes from '../websocket/types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getRestroomSuccess: {
      const { conversations } = action;
      return conversations.map(({ id }) => id);
    }
    case types.addSuccess: {
      const { id } = action;
      return uniq([...state, id]);
    }
    case websocketTypes.restroom: {
      const {
        conversation: { id },
      } = action;
      return uniq([...state, id]);
    }
    case queuesTypes.removeFromQueue:
    case reservedConversationTypes.deleted:
    case websocketTypes.deleteCon:
    case websocketTypes.reserve:
    case reservedConversationTypes.reserveConversation: {
      const { id } = action;
      return state.filter((item) => item !== id);
    }
    case websocketTypes.create: {
      const {
        conversation: { id },
      } = action;
      return state.filter((item) => item !== id);
    }
    case types.flushSuccess: {
      const { ids } = action;
      return difference(state, ids);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getRestroomSuccess: {
      const { conversations } = action;
      return keyBy(
        conversations.map(
          ({ id, queueName, inRestroomSince, reason, timeZone, language }) => ({
            id,
            queueName,
            inRestroomSince,
            reason,
            timeZone,
            language,
          })
        ),
        'id'
      );
    }

    case types.addSuccess: {
      const { queueName, id, inRestroomSince, reason, timeZone } = action;
      return {
        ...state,
        [id]: {
          id,
          queueName,
          inRestroomSince,
          reason,
          timeZone,
          myItem: true,
        },
      };
    }
    case websocketTypes.restroom: {
      const {
        queueName,
        conversation: { id, timeZone },
        inRestroomSince,
        reason,
      } = action;
      return {
        ...state,
        [id]: {
          id,
          queueName,
          inRestroomSince,
          reason,
          timeZone,
        },
      };
    }
    case reservedConversationTypes.deleted:
    case websocketTypes.deleteCon:
    case websocketTypes.reserve:
    case reservedConversationTypes.reserveConversation: {
      const { id } = action;
      return omit(state, id);
    }
    case websocketTypes.create: {
      const {
        conversation: { id },
      } = action;
      return omit(state, id);
    }
    case types.flushSuccess:
      return omit(state, action.ids);
    default:
      return state;
  }
};

export default combineReducers({ allIds: allIdsReducer, byId: byIdReducer });
