import { createSelector } from 'reselect';

const getPrereservedConversations = (state) => state.prereservedConversations;

const getPrereservedConversationIds = (state) => getPrereservedConversations(state).sortedIds;

const getPrereservedConversationsById = (state) => getPrereservedConversations(state).byId;

const getSortedPrereservedConversations = createSelector(
  getPrereservedConversationIds,
  getPrereservedConversationsById,
  (ids, items) => ids.map((id) => items[id])
);

const getFirstPrereservedConversation = createSelector(getSortedPrereservedConversations, (array) =>
  array.length > 0 ? array[0] : null
);

const getPrereservedCount = createSelector(getPrereservedConversationIds, (array) => array.length);

export default { getFirstPrereservedConversation, getPrereservedCount };
