import { uniq, omitBy, assignWith, keyBy } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import messagesTypes from '../messages/types';
import { profilesTypes } from '../profiles';
import websocketTypes from '../websocket/types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.restroom:
    case websocketTypes.create: {
      const {
        conversation: { id },
      } = action;
      return uniq([...state, id]);
    }
    case types.removeConversation: {
      const { id } = action;
      return state.filter((item) => item !== id);
    }
    case types.addConversations: {
      const { conversations } = action;
      return uniq([...state, ...conversations.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.restroom:
    case websocketTypes.create: {
      const { conversation } = action;
      const { id } = conversation;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    case types.removeConversation: {
      const { id } = action;
      return omitBy(state, id);
    }
    case types.getStatisticsSuccess:
    case types.getConversation:
    case types.getConversationFailure:
    case types.loadMore:
    case types.loadMoreFailure:
    case types.getConversationSuccess:
    case types.loadMoreSuccess: {
      const { id } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    case types.addConversations: {
      const { conversations } = action;
      return assignWith({}, state, keyBy(conversations, 'id'), (stateVal, conversationVal) =>
        stateVal && conversationVal
          ? {
              ...stateVal,
              ...conversationVal,
              isLoading: conversationVal ? false : stateVal.isLoading,
              loaded: conversationVal ? false : stateVal.loaded,
            }
          : stateVal || conversationVal
      );
    }
    case messagesTypes.sendIceBreakerSuccess: {
      const {
        message: { conversationId: id },
      } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    case profilesTypes.visitProfileSuccess: {
      const { conversationId } = action;
      return {
        ...state,
        [conversationId]: valueReducer(state[conversationId], action),
      };
    }
    default:
      return state;
  }
};

const valueReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.restroom:
    case websocketTypes.create: {
      const { conversation } = action;
      return {
        ...state,
        ...conversation,
        users: conversation.users.map(({ id }) => id),
        isLoading: false,
        loaded: false,
      };
    }
    case types.getConversationSuccess: {
      const {
        conversation: { canSendIceBreaker, ...conversation },
      } = action;
      return {
        ...state,
        isLoading: false,
        loaded: true,
        nextPage: 2,
        ...conversation,
        canSendIceBreaker: canSendIceBreaker[state.moderatedUser],
      };
    }
    case types.loadMoreSuccess: {
      const { moreRecords } = action;
      return {
        ...state,
        moreRecords,
        isLoading: false,
        nextPage: state.nextPage + 1,
      };
    }
    case types.getConversation:
    case types.loadMore:
      return { ...state, isLoading: true };
    case types.loadMoreFailure:
    case types.getConversationFailure:
      return { ...state, isLoading: false, error: action.error };
    case types.getStatisticsSuccess:
      return { ...state, statistics: action.statistics };
    case messagesTypes.sendIceBreakerSuccess:
      return { ...state, canSendIceBreaker: false };
    case profilesTypes.visitProfileSuccess: {
      const { userId, timestamp } = action;

      return {
        ...state,
        visitDate: { ...state.visitDate, [userId]: timestamp },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer, allIds: allIdsReducer });
