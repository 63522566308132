import {
  call,
  put,
  takeEvery,
  fork,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import {
  GetProfile,
  simulateVisit,
  reportClient,
  UpdateProfile,
  SetStatements,
} from '../services/ModerationApi';

function* getProfile({ userId, conversationId }) {
  try {
    const profile = yield call(GetProfile, userId, conversationId);
    yield put(actions.getProfileSuccess(userId, profile));
  } catch (error) {
    yield put(actions.getProfileFailure(error));
  }
}

function* handleGetProfile() {
  yield takeEvery(types.loadProfile, getProfile);
}

function* visitProfile({ id, asUser, conversationId }) {
  try {
    yield call(simulateVisit, id, asUser);
    yield put(actions.visitProfileSuccess(conversationId, asUser));
  } catch (error) {
    yield put(actions.visitProfileFailure(error));
  }
}

function* handleVisitProfile() {
  yield takeLeading(types.visitProfile, visitProfile);
}

function* report({ id, conversationId, reason, flags }) {
  try {
    yield call(reportClient, id, conversationId, reason, flags);
    yield put(actions.reportProfileSuccess(id));
  } catch (error) {
    yield put(actions.reportProfileFailure(error));
  }
}

function* handleReport() {
  yield takeLatest(types.reportProfile, report);
}

function* update({ id, profile }) {
  try {
    yield call(UpdateProfile, id, profile);
    yield put(actions.updateProfileSuccess(id, profile));
  } catch (error) {
    yield put(actions.updateProfileFailure(error));
    yield fork(getProfile, { userId: id });
  }
}

function* handleUpdate() {
  yield takeLeading(types.updateProfile, update);
}

function* setStatement({ id, statement }) {
  try {
    yield call(SetStatements, id, statement);
    yield put(actions.setStatementSuccess(id, statement));
  } catch (error) {
    yield put(actions.setStatementFailure(error));
  }
}

function* handleSetStatement() {
  yield takeLeading(types.setStatement, setStatement);
}

export default {
  handleGetProfile,
  handleVisitProfile,
  handleReport,
  handleUpdate,
  handleSetStatement,
};
