import { combineReducers } from 'redux';
import { uniq, keyBy, omitBy, mapValues } from 'lodash';
import types from './types';
import { conversationsTypes } from '../conversations';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.addEntrySuccess: {
      const { id } = action;
      return uniq([...state, id]);
    }
    case types.getEntriesSuccess: {
      const { entries } = action;
      return uniq([...state, ...entries.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.addEntrySuccess: {
      const { id, entry } = action;
      return { ...state, [id]: { id, ...entry } };
    }
    case types.getEntriesSuccess: {
      const { entries } = action;
      return { ...state, ...keyBy(entries, 'id') };
    }
    case conversationsTypes.removeConversation: {
      const { id } = action;
      return omitBy(state, ({ conversationId }) => conversationId === id);
    }
    case types.editEntry: {
      const { id, edit } = action;

      return mapValues(state, (value, key) => ({
        ...value,
        inEdit: key.toString() === id.toString() && edit,
      }));
    }
    case types.changeEntrySuccess: {
      const { id } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    default:
      return state;
  }
};

const valueReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.changeEntrySuccess: {
      const { text } = action;
      return { ...state, text };
    }
    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer, allIds: allIdsReducer });
