import { take, call, put, fork, race, cancel, actionChannel, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import createEventChannel from './eventChannel';
import { configurationSelectors, getFromConfig } from '../configuration';
import { agentSelectors, agentTypes } from '../agent';
import { queuesTypes } from '../queues';
import actions from './actions';
import types from './types';
import ChannelDescription from '../ChannelDescription';

function* onMessage({ queue, type, id, action, data }) {
  if (!action) {
    return;
  }
  const channelName = new ChannelDescription(id, queue, type).toString();
  console.log(action, data);
  switch (action) {
    case 'incoming': {
      const {
        text,
        user_guid: userId,
        created_at: createdAt,
        id,
        MessageType,
        preview,
        conversation: { hash_id: conversationId },
        agentName,
      } = data;
      yield put(
        actions.incoming({
          id,
          text,
          agentName,
          preview,
          messageType: MessageType,
          createdAt,
          userId,
          conversationId,
        })
      );
      break;
    }
    case 'restroom': {
      const userLanguage = yield select(agentSelectors.getLanguage);
      if (userLanguage === data.conversation.language) {
        yield put(actions.restroom(data.conversation, data.reason, channelName));
      }
      break;
    }
    case 'delete': {
      yield put(actions.deleteCon(channelName, id));
      break;
    }
    case 'flush': {
      yield put(actions.flush(channelName, data.timeZone));
      break;
    }
    case 'in': {
      yield put(actions.sessionIn());
      break;
    }
    case 'create': {
      const userLanguage = yield select(agentSelectors.getLanguage);
      console.log(userLanguage, data.language);
      if (userLanguage === data.language) {
        const {
          conversationHash: id,
          user: users,
          moderatedUser,
          updated_at: inQueueSince,
          asaLevel,
          timeZone,
          language,
        } = data;
        yield put(
          actions.create(
            {
              id,
              moderatedUser,
              level: asaLevel || 0,
              inQueueSince,
              timeZone,
              language,
              users: users.map(
                ({ firstname: name, profileImage: image, guid: userId, gender }) => ({
                  id: userId,
                  image,
                  name,
                  gender,
                })
              ),
            },
            channelName
          )
        );
      }
      break;
    }
    case 'reserve': {
      yield put(actions.reserve(channelName, data.id));
      break;
    }
    default:
      break;
  }
}

function* receiveFromChannel(eventChannel) {
  let waitForLoad = true;
  try {
    let storedMessages = [];
    const knownTransactions = new Set();
    while (true) {
      const { load, message, send } = yield race({
        load: take(queuesTypes.loaded),
        send: take(types.sendMessage),
        message: take(eventChannel),
      });
      const { transactionId } = message || send || {};
      if (message && waitForLoad) {
        storedMessages.push(message);
      }
      if (message && !waitForLoad && (!transactionId || !knownTransactions.has(transactionId))) {
        yield fork(onMessage, message);
      }
      if (transactionId) {
        knownTransactions.add(transactionId);
      }
      if (load && waitForLoad) {
        waitForLoad = false;
        for (let i = 0; i < storedMessages.length; i += 1) {
          yield fork(onMessage, storedMessages[i]);
        }
        storedMessages = [];
      }
    }
  } catch (error) {
    console.warn(error);
  }
}

function* sendMessages(client, channels) {
  const requestChannel = yield actionChannel(types.sendMessage);
  while (true) {
    const { queueName, action, data, transactionId } = yield take(requestChannel);
    const channel = ChannelDescription.fromString(queueName);
    const messageObject = {
      transactionId,
      action,
      type: channel.messageType,
      data,
    };
    client.publish({
      destination: channels.find(
        ({ type: queueType, id: queueId }) =>
          queueType === channel.queueType && queueId === channel.id
      ).url,
      body: JSON.stringify(messageObject),
    });
  }
}

function* openWebSocketChannel() {
  while (true) {
    const connection = yield* getFromConfig(configurationSelectors.getConnections);
    console.log(connection);
    const { channels, url, username, password } = connection;
    const { client, channel: eventChannel } = yield call(
      createEventChannel,
      url,
      username,
      password,
      channels
    );
    yield take(eventChannel);
    yield put(actions.connected(connection));
    try {
      const sendMessageTask = yield fork(sendMessages, client, channels);
      const recieveMessageTask = yield fork(receiveFromChannel, eventChannel);
      yield take(agentTypes.logoutSuccess);
      yield cancel(sendMessageTask);
      yield cancel(recieveMessageTask);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      eventChannel.close();
    }
  }
}

export default { openWebSocketChannel };
