import { combineReducers } from 'redux';
import messagesTypes from '../messages/types';
import presentsTypes from '../presents/types';
import types from './types';

const conversationReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.takePrereservedConversation:
    case types.reserveConversationSuccess: {
      const { id } = action;
      return id;
    }
    case types.discardConversation:
    case types.emptyQueue:
      return null;
    default:
      return state;
  }
};

const currentQueueReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.takePrereservedConversation:
    case types.reserveConversationSuccess: {
      const { queueName } = action;
      return queueName;
    }
    default:
      return state;
  }
};

const asaLevelReducer = (state = 0, action) => {
  switch (action.type) {
    case types.reserveConversationSuccess:
    case types.takePrereservedConversation:
      return action.asaLevel;

    default:
      return state;
  }
};

const timeZone = (state = 'Europa/Berlin', action) => {
  switch (action.type) {
    case types.reserveConversationSuccess:
    case types.takePrereservedConversation:
      return action.timeZone;
    default:
      return state;
  }
};

const language = (state = null, action) => {
  switch (action.type) {
    case types.reserveConversationSuccess:
    case types.takePrereservedConversation:
      return action.language;

    default:
      return state;
  }
};

const startTimeReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.takePrereservedConversation:
    case types.reserveConversationSuccess:
    case types.holdMessageSuccess: {
      const { startTime } = action;
      return startTime;
    }
    case types.sendMessageSuccess:
    case types.handOverSuccess:
    case types.freezeConversationSuccess:
    case types.discardConversation:
    case types.emptyQueue:
      return null;
    default:
      return state;
  }
};

const messageFailureReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.sendMessageFailure:
      return action.error;
    case types.sendMessageSuccess:
    case types.reserveConversationSuccess:
    case types.clearFailure:
      return null;
    default:
      return state;
  }
};

const incomingReducer = (state = false, action) => {
  const { type, isIncoming } = action;
  switch (type) {
    case types.reserveConversationSuccess:
      return isIncoming;
    default:
      return state;
  }
};

const sentMessagesReducer = (state = [], action) => {
  switch (action.type) {
    case types.sendMessageSuccess:
      return [...state, { text: action.message.text, time: action.usedTime }];
    default:
      return state;
  }
};

const isInSending = (state = false, action) => {
  switch (action.type) {
    case types.reserveConversationSuccess: {
      return false;
    }
    case presentsTypes.sendPresentSuccess:
    case messagesTypes.sendIceBreakerSuccess: {
      return true;
    }
    default:
      return state;
  }
};

export default combineReducers({
  conversationId: conversationReducer,
  currentQueue: currentQueueReducer,
  startTime: startTimeReducer,
  failure: messageFailureReducer,
  incoming: incomingReducer,
  sentMessages: sentMessagesReducer,
  asaLevel: asaLevelReducer,
  timeZone,
  language,
  isInSending,
});
