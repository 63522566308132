const discardConversation = 'site/reservedCon/DISCARD';
const reserveNextConversation = 'site/reservedCon/RESERVENEXT';
const reserveConversation = 'site/reservedCon/RESERVE';
const reserveConversationSuccess = 'site/reservedCon/RESERVESUCCESS';
const reserveConversationFailure = 'site/reservedCon/RESERVEFAILURE';
const freezeConversation = 'site/conversation/FREEZE';
const freezeConversationSuccess = 'site/conversation/FREEZESUCCESS';
const freezeConversationFailure = 'site/conversation/FREEZEFAILURE';
const sendMessage = 'site/reservedCon/SENDMESSAGE';
const holdMessageSuccess = 'site/reservedCon/SENDANDHOLDSUCCESS';
const sendMessageSuccess = 'site/reservedCon/SENDMESSAGESUCCESS';
const sendMessageFailure = 'site/reservedCon/SENDMESSAGEFAILURE';
const handOver = 'site/reservedCon/HANDOVER';
const handOverSuccess = 'site/reservedCon/HANDOVERSUCCESS';
const handOverFailure = 'site/reservedCon/HANDOVERFAILURE';
const emptyQueue = 'site/reservedCon/EMPTYQUEUE';
const clearFailure = 'site/reservedCon/CLEARFAILURE';
const deleted = 'site/reservedCon/DELETED';
const takePrereservedConversation = 'site/reservedCon/TAKEPRE';

export default {
  discardConversation,
  reserveNextConversation,
  reserveConversation,
  reserveConversationSuccess,
  reserveConversationFailure,
  freezeConversation,
  freezeConversationSuccess,
  freezeConversationFailure,
  sendMessage,
  holdMessageSuccess,
  sendMessageSuccess,
  sendMessageFailure,
  handOver,
  handOverSuccess,
  handOverFailure,
  emptyQueue,
  clearFailure,
  deleted,
  takePrereservedConversation,
};
