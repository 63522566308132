import { createSelector } from 'reselect';

const getUsersById = (state) => state.users.byId;

const getAllUserIds = (state) => state.users.allIds;

const getAllUsers = createSelector(
  getAllUserIds,
  getUsersById,
  (ids, items) => ids.map(id => items[id]),
);

const getUserId = (_, { id }) => id;

const makeGetUser = () => createSelector(
  getUsersById,
  getUserId,
  (users, id) => users[id],
);

export default { makeGetUser, getUsersById };
