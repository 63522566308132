import { createSelector } from 'reselect';

const getProfiles = ({ profiles }) => profiles;

const getIsVisiting = createSelector(getProfiles, ({ isVisiting }) => isVisiting);

const getProfilesById = createSelector(getProfiles, ({ byId }) => byId);

const getIsReporting = state => getProfiles(state).isReporting;

const getId = (_, { id }) => id;

const makeGetProfile = () => createSelector(getProfilesById, getId, (profiles, id) => profiles[id]);

const makeGetLocation = () =>
  createSelector(
    (_, { userId }) => userId,
    getProfilesById,
    (id, profiles) => ({ lng: profiles[id]?.lng, lat: profiles[id]?.lat })
  );

export default { makeGetProfile, getIsVisiting, getIsReporting, makeGetLocation };
