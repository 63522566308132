import { createSelector, createStructuredSelector } from 'reselect';
import ChannelDescription from '../ChannelDescription';
import { conversationSelectors } from '../conversations';

const getConversationId = ({ reservedConversation: { conversationId } }) => conversationId;

const getCurrentQueue = ({ reservedConversation: { currentQueue } }) => currentQueue;

const getAsaLevel = ({ reservedConversation: { asaLevel } }) => asaLevel;

const getStartTime = (state) => state.reservedConversation.startTime;

const getFailure = (state) => state.reservedConversation.failure;

const getIncoming = (state) => state.reservedConversation.incoming;

const getSentMessages = (state) => state.reservedConversation.sentMessages;

const getTimeZone = (state) => state.reservedConversation.timeZone;

const getLanguage = (state) => state.reservedConversation.language;

const getLastMessagesTexts = createSelector(getSentMessages, (messages) =>
  messages.map(({ text }) => text)
);

const inConversation = createSelector(getConversationId, (id) => id !== null);

const getReservedConversation = createSelector(
  conversationSelectors.getConversationsWithUsers,
  getConversationId,
  getCurrentQueue,
  getAsaLevel,
  getTimeZone,
  getLanguage,
  (conversations, id, queueName, asaLevel, timeZone, language) =>
    conversations[id] && { ...conversations[id], queueName, asaLevel, timeZone, language }
);

const showBadWordFilter = createSelector(
  getFailure,
  (failure) => failure && failure.statusMsg === 'Badwords'
);

const getBadWords = createSelector(
  getFailure,
  (failure) => (failure && failure.items && failure.items.matched) || []
);

const getReserveInformation = createStructuredSelector({
  id: getConversationId,
  startTime: getStartTime,
});

const getQueue = createSelector(getCurrentQueue, (queueName) =>
  ChannelDescription.fromString(queueName)
);

const getInSending = (state) =>
  state.reservedConversation.isInSending && !!state.reservedConversation.conversationId;

export default {
  getReservedConversation,
  getConversationId,
  getIncoming,
  inConversation,
  showBadWordFilter,
  getBadWords,
  getStartTime,
  getReserveInformation,
  getLastMessagesTexts,
  getSentMessages,
  getQueue,
  getInSending,
};
