import { formatISO } from 'date-fns';
import types from './types';

const prereserveConversation = (id, queueName, asaLevel, timeZone, language) => ({
  type: types.prereserveConversation,
  id,
  queueName,
  asaLevel,
  timeZone,
  language,
});
const prereserveConversationSuccess = (id, queueName, asaLevel, timeZone, language) => ({
  type: types.prereserveConversationSuccess,
  id,
  queueName,
  asaLevel,
  timeZone,
  language,
  reserveTime: formatISO(new Date()),
});
const prereserveConversationFailure = (error) => ({
  type: types.prereserveConversationFailure,
  error,
});

const discardReservedConversation = (id) => ({
  type: types.discardReservedConversation,
  id,
});
const discardReservedConversationSuccess = (id) => ({
  type: types.discardReservedConversationSuccess,
  id,
});
const discardReservedConversationFailure = (error, id) => ({
  type: types.discardReservedConversationFailure,
  id,
  error,
});

const remove = (id) => ({ type: types.remove, id });

export default {
  prereserveConversation,
  prereserveConversationSuccess,
  prereserveConversationFailure,
  discardReservedConversation,
  discardReservedConversationSuccess,
  discardReservedConversationFailure,
  remove,
};
