const addUsers = 'site/users/ADDMULTIPLE';
const freezeUser = 'site/users/FREEZE';
const freezeUserSuccess = 'site/users/FREEZESUCCESS';
const freezeUserFailure = 'site/users/FREEZEFAILURE';

export default {
  addUsers,
  freezeUser,
  freezeUserSuccess,
  freezeUserFailure,
};
