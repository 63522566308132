const addConversations = 'site/conversation/ADDMULTIPLE';
const removeConversation = 'site/conversation/REMOVE';
const getConversation = 'site/conversation/GET';
const getConversationSuccess = 'site/conversation/GETSUCCESS';
const getConversationFailure = 'site/conversation/GETFAILURE';
const loadMore = 'site/conversation/LOADMORE';
const loadMoreSuccess = 'site/conversation/LOADMORESUCCESS';
const loadMoreFailure = 'site/conversation/LOADMOREFAILURE';
const getStatistics = 'site/conversation/GETSTATISTICS';
const getStatisticsSuccess = 'site/conversation/GETSTATISTICSSUCCESS';
const getStatisticsFailure = 'site/conversation/GETSTATISTICSFAILURE';
const addConversationsById = 'site/conversations/ADDBYID';
const loadConversationsFailure = 'site/conversations/ADDFAILURE';

export default {
  addConversations,
  removeConversation,
  getConversation,
  getConversationSuccess,
  getConversationFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  getStatistics,
  getStatisticsSuccess,
  getStatisticsFailure,
  addConversationsById,
  loadConversationsFailure,
};
