import { reverse, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';

const getAllEntries = ({ conversationInformationEntries: { byId, allIds } }) =>
  allIds.map((item) => byId[item]);

const getConversationId = (_, { id }) => id;

const getConversationEntries = createSelector(getAllEntries, getConversationId, (entries, id) =>
  reverse(
    sortBy(
      entries.filter(({ conversationId }) => conversationId === id),
      ({ createdAt }) => parseISO(createdAt).getTime()
    )
  )
);

export default { getConversationEntries };
