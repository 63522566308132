import { put, call, takeEvery, takeLeading } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetDialogInfos, CreateDialogInfo, UpdateDialogInfo } from '../services/ModerationApi';

function* getEntries({ conversationId }) {
  try {
    const entries = yield call(GetDialogInfos, conversationId);
    const filledEntries = entries.map(item => ({ ...item, conversationId }));
    yield put(actions.getEntriesSuccess(filledEntries));
  } catch (error) {
    yield put(actions.getEntriesFailure(error));
  }
}

function* handleGetEntries() {
  yield takeLeading(types.getEntries, getEntries);
}

function* createEntry({ id, text, regardingClient }) {
  try {
    const entry = yield call(CreateDialogInfo, id, text, regardingClient);
    const extendedEntry = { ...entry, conversationId: id };
    yield put(actions.addEntrySuccess(extendedEntry.id, extendedEntry));
  } catch (error) {
    yield put(actions.addEntryFailure(error));
  }
}

function* handleCreateEntry() {
  yield takeEvery(types.addEntry, createEntry);
}

function* updateEntry({ id, text }) {
  try {
    yield call(UpdateDialogInfo, id, text);
    yield put(actions.changeEntrySuccess(id, text));
  } catch (error) {
    yield put(actions.changeEntryFailure(id, error));
  }
}

function* handleUpdateEntry() {
  yield takeEvery(types.changeEntry, updateEntry);
}

export default { handleCreateEntry, handleGetEntries, handleUpdateEntry };
