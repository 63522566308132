import { put, call, takeEvery, select, delay, race, take } from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import { Pause, SendCurrentPage } from '../services/ModerationApi';
import selectors from './selectors';
import { getFromConfig, configurationSelectors } from '../configuration';
import { agentTypes, agentSelectors } from '../agent';
import { reservedConversationTypes } from '../reservedConversation';
import { queuesTypes, queuesSelectors } from '../queues';
import { websocketTypes } from '../websocket';

function* pause() {
  try {
    const isPaused = yield select(selectors.getIsPaused);
    yield call(Pause, isPaused);
  } catch (error) {
    yield put(actions.pauseError(error));
  }
}

function* handlePause() {
  yield takeEvery(types.togglePause, pause);
}

function* handleReservedOnce() {
  while (true) {
    yield take(queuesTypes.loaded);
    const isEmptyQueue = yield select(queuesSelectors.getQueuesEmpty);
    const hasAcceptedTos = yield select(agentSelectors.getAcceptedTos);
    if (!isEmptyQueue && hasAcceptedTos) {
      const { type } = yield take([
        reservedConversationTypes.reserveConversationSuccess,
        agentTypes.logout,
      ]);
      if (type === agentTypes.logout) {
        continue;
      }
    }
    yield put(actions.hasReservedOnce());
  }
}

function* sendOnLandingPage() {
  while (true) {
    const { sendPageTimer } = yield getFromConfig(configurationSelectors.getAuth);
    yield take(types.hasReservedOnce);
    let page = yield select(selectors.getPage);
    if (!page) {
      page = 'landing';
    }
    while (true) {
      const { logout, time, changePage } = yield race({
        logout: take(agentTypes.logoutSuccess),
        time: delay(sendPageTimer * 1000, true),
        changePage: take(types.changePage),
      });
      if (logout) {
        break;
      }
      if (changePage) {
        const { page: currentPage } = changePage;
        try {
          page = currentPage;
          yield call(SendCurrentPage, currentPage);
          yield put(actions.changePageSuccess());
        } catch (error) {
          yield put(actions.changePageFailure(error));
        }
      }
      if (time && page === 'landing') {
        try {
          yield call(SendCurrentPage, page);
          yield put(actions.changePageSuccess());
        } catch (error) {
          yield put(actions.changePageFailure(error));
        }
      }
    }
  }
}

export default {
  handlePause,
  sendOnLandingPage,
  handleReservedOnce,
};
