import {
  put, call, takeLatest, select, take, race,
} from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import actions from './actions';
import { GetConfig } from '../services/ModerationApi';
import types from './types';

function* getConfiguration() {
  try {
    const configuration = yield call(GetConfig);
    yield put(actions.getConfigurationSuccess(configuration));
  } catch (error) {
    yield put(actions.getConfigurationFailure(error));
  }
}

function* handleGetConfiguration() {
  yield takeLatest(types.getConfiguration, getConfiguration);
}

function* getFromConfig(selector) {
  let configPart = yield select(selector);
  while (!configPart) {
    const { rehydrate, getConfig } = yield race({
      rehydrate: take(REHYDRATE),
      getConfig: take(types.getConfigurationSuccess),
    });
    if (rehydrate) {
      const { payload } = rehydrate;
      if (payload) {
        configPart = selector(payload);
      }
    }
    if (getConfig) {
      configPart = selector(getConfig);
    }
  }
  return configPart;
}

export { getFromConfig };

export default {
  handleGetConfiguration,
};
