const loadProfile = 'site/profiles/LOADPROFILE';
const loadProfileSuccess = 'site/profiles/LOADPROFILESUCCESS';
const loadProfileFailure = 'site/profiles/LOADPROFILEFAILURE';
const visitProfile = 'site/profiles/VISIT';
const visitProfileSuccess = 'site/profiles/VISITSUCCESS';
const visitProfileFailure = 'site/profiles/VISITFAILURE';
const reportProfile = 'site/profiles/REPORT';
const reportProfileSuccess = 'site/profiles/REPORTSUCCESS';
const reportProfileFailure = 'site/profiles/REPORTFAILURE';
const updateProfile = 'site/profiles/UPDATE';
const updateProfileSuccess = 'site/profiles/UPDATESUCCESS';
const updateProfileFailure = 'site/profiles/UPDATEFAILURE';

const setStatement = 'site/profiles/SETSTATEMENT';
const setStatementSuccess = 'site/profiles/SETSTATEMENTSUCCESS';
const setStatementFailure = 'site/profiles/SETSTATEMENTFAILURE';

export default {
  loadProfile,
  loadProfileSuccess,
  loadProfileFailure,
  visitProfile,
  visitProfileSuccess,
  visitProfileFailure,
  reportProfile,
  reportProfileSuccess,
  reportProfileFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  setStatement,
  setStatementSuccess,
  setStatementFailure,
};
