const addEntry = 'site/cie/ADD';
const addEntrySuccess = 'site/cie/ADDSUCCESS';
const addEntryFailure = 'site/cie/ADDFAILURE';
const getEntries = 'site/cie/GET';
const getEntriesSuccess = 'site/cie/GETSUCCESS';
const getEntriesFailure = 'site/cie/GETFAILURE';
const editEntry = 'site/cie/EDIT';
const changeEntry = 'site/cie/CHANGE';
const changeEntrySuccess = 'site/cie/CHANGESUCCESS';
const changeEntryFailure = 'site/cie/CHANGEFAILURE';

export default {
  addEntry,
  addEntrySuccess,
  addEntryFailure,
  getEntries,
  getEntriesSuccess,
  getEntriesFailure,
  editEntry,
  changeEntry,
  changeEntrySuccess,
  changeEntryFailure,
};
