const login = 'site/agent/LOGIN';
const loginSuccess = 'site/agent/LOGINSUCCESS';
const loginFailure = 'site/agent/LOGINFAILURE';
const logout = 'site/agent/LOGOUT';
const forceLogout = 'site/agent/FORCELOGOUT';
const logoutSuccess = 'site/agent/LOGOUTSUCCESS';
const logoutFailure = 'site/agent/LOGOUTFAILURE';
const changePassword = 'site/agent/CHANGEPASSWORD';
const changePasswordSuccess = 'site/agent/CHANGEPASSWORDSUCCESS';
const changePasswordFailure = 'site/agent/CHANGEPASSWORDFAILURE';
const acceptTos = 'site/agent/ACCEPTTOS';
const acceptTosSuccess = 'site/agent/ACCEPTTOSSUCCESS';
const acceptTosFailure = 'site/agent/ACCEPTTOSFAILURE';

export default {
  login,
  loginSuccess,
  loginFailure,
  logout,
  forceLogout,
  logoutSuccess,
  logoutFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  acceptTos,
  acceptTosSuccess,
  acceptTosFailure,
};
