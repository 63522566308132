class ChannelInformation {
  #type;

  #id;

  #url;

  constructor(type, id, url) {
    this.#id = id;
    this.#type = type;
    this.#url = url;
  }

  get type() {
    return this.#type;
  }

  get id() {
    return this.#id;
  }

  get url() {
    return this.#url;
  }

  toString() {
    return `${this.#type}-${this.#id}`;
  }

  equal(other) {
    return other instanceof ChannelInformation && other.url === this.url;
  }

  toValue() {
    return { url: this.#url, type: this.#type, id: this.#id, instance: 'ChannelInformation' };
  }
}

export default ChannelInformation;
