const prereserveConversation = 'site/prereservedConversation/RESERVE';
const prereserveConversationSuccess = 'site/prereservedConversation/RESERVESUCCESS';
const prereserveConversationFailure = 'site/prereservedConversation/RESERVEFAILURE';
const discardReservedConversation = 'site/prereservedConversation/DISCARD';
const discardReservedConversationSuccess = 'site/prereservedConversation/DISCARDSUCCESS';
const discardReservedConversationFailure = 'site/prereservedConversation/DISCARDFAILURE';
const remove = 'site/prereservedConversation/REMOVE';

export default {
  prereserveConversation,
  prereserveConversationSuccess,
  prereserveConversationFailure,
  discardReservedConversation,
  discardReservedConversationSuccess,
  discardReservedConversationFailure,
  remove,
};
