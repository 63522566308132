import {
  put,
  call,
  select,
  take,
  takeEvery,
  race,
  delay,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import Router from 'next/router';
import { takeRight, union, intersection, chunk, max } from 'lodash';
import { differenceInSeconds, parseISO } from 'date-fns';
import types from './types';
import actions from './actions';
import { queuesSelectors } from '../queues';
import { restroomTypes } from '../restroom';
import {
  ReserveConversation,
  CreateMessage,
  DiscardConversation,
  AssignFromRestroom,
  Freeze,
  reportAgent,
  SetLanguage,
} from '../services/ModerationApi';
import selectors from './selectors';
import { configurationSelectors, getFromConfig } from '../configuration';
import { agentTypes, agentActions } from '../agent';
import { websocketActions, websocketTypes } from '../websocket';
import { prereservedConversationSelectors } from '../prereservedConversations';

function* selectNextConversation() {
  const conversation = yield select(queuesSelectors.getNextViableReservation);
  return conversation;
}

function* unreserveCheck() {
  const messageConfig = yield* getFromConfig(configurationSelectors.getMessageConfig);
  const { maxTime } = messageConfig;
  if (maxTime) {
    let loggedIn = true;
    while (loggedIn) {
      let reserveInformation = yield select(selectors.getReserveInformation);
      if (!reserveInformation.id) {
        reserveInformation = yield take([
          types.reserveConversationSuccess,
          types.takePrereservedConversation,
        ]);
      }
      let isFinished = false;
      const { id, startTime } = reserveInformation;
      const startTimeDate = parseISO(startTime);
      while (!isFinished) {
        const { onTime, logout, finish } = yield race({
          finish: take([
            types.reserveNextConversation,
            restroomTypes.addSuccess,
            types.sendMessageSuccess,
            types.freezeConversationSuccess,
            types.discardConversation,
          ]),
          logout: take(agentTypes.logoutSuccess),
          onTime: delay(1000, true),
        });
        if (finish) {
          isFinished = true;
        }
        if (onTime && differenceInSeconds(new Date(), startTimeDate) > maxTime) {
          try {
            yield call(DiscardConversation, id);
          } catch (error) {
            console.warn(error);
          } finally {
            isFinished = true;
            yield put(actions.reserveNextConversation());
          }
        }
        if (logout) {
          isFinished = true;
          loggedIn = false;
        }
      }
    }
  }
}

function* reserveLoop() {
  while (true) {
    const { isIncoming, type } = yield take([
      types.reserveNextConversation,
      restroomTypes.addSuccess,
      types.sendMessageSuccess,
      types.freezeConversationSuccess,
      types.handOverSuccess,
    ]);
    let continueReserve = false;
    do {
      const prereservedConversation = yield select(
        prereservedConversationSelectors.getFirstPrereservedConversation
      );
      console.log('PossibleConversation: ', prereservedConversation);
      if (prereservedConversation) {
        const { id, queueName, asaLevel, timeZone, language } = prereservedConversation;
        yield put(actions.takePrereservedConversation(id, queueName, asaLevel, timeZone, language));
      } else {
        const conversation = yield call(selectNextConversation);
        if (conversation) {
          const { id, queueName, level, timeZone, language } = conversation;
          yield put(
            actions.reserveConversation(id, queueName, level, false, isIncoming, timeZone, language)
          );
          const { success, error } = yield race({
            success: take(types.reserveConversationSuccess),
            error: take(types.reserveConversationFailure),
          });
          continueReserve = !success;
        } else {
          yield put(actions.emptyQueue());
          if (Router.route === '/conversation') {
            Router.back();
          }
          continueReserve = false;
        }
      }
    } while (continueReserve);
  }
}

function* reserveConversation({
  id,
  queueName,
  asaLevel,
  fromRestroom,
  fromOutOfQueue,
  isIncoming,
  timeZone,
  language,
}) {
  if (isIncoming) {
    yield delay(Math.random() * 1000);
  }
  yield put(websocketActions.sendMessage(queueName, 'reserve', { id }));
  try {
    yield call(fromRestroom ? AssignFromRestroom : ReserveConversation, id, true, fromOutOfQueue);
    yield put(
      actions.reserveConversationSuccess(id, queueName, asaLevel, isIncoming, timeZone, language)
    );
  } catch (error) {
    yield put(actions.reserveConversationFailure(error, id));
  }
}

function* handleReserveConversation() {
  yield takeEvery(types.reserveConversation, reserveConversation);
}

function* sendMessage({ id, text, hold, freezeDuration, reason }) {
  try {
    const startTime = yield select(selectors.getStartTime);
    const durationSeconds = Math.floor(differenceInSeconds(new Date(), parseISO(startTime)));
    const { message, queueType } = yield call(CreateMessage, {
      conversationId: id,
      messageType: 'text',
      message: text,
      duration: durationSeconds,
      hold,
      freezeDuration,
      reason,
    });
    const editedMessage = { ...message, conversationId: id };
    if (hold) {
      yield put(
        actions.holdMessageSuccess(editedMessage.id, editedMessage, durationSeconds, queueType)
      );
    } else {
      yield put(
        actions.sendMessageSuccess(editedMessage.id, editedMessage, durationSeconds, queueType)
      );
    }
  } catch (error) {
    yield put(actions.sendMessageFailure(error));
  }
}

function* handleSendMessage() {
  yield takeLeading(types.sendMessage, sendMessage);
}

function* discardConversation() {
  while (true) {
    const { id, withRouting } = yield take(types.discardConversation);
    if (id) {
      yield call(DiscardConversation, id);
      if (withRouting) Router.back();
    }
  }
}

function* freezeConversation({ id, duration, reason }) {
  try {
    yield call(Freeze, {
      id,
      duration,
      reason,
    });
    yield put(actions.freezeConversationSuccess(id, duration));
  } catch (error) {
    yield put(actions.freezeConversationFailure(error));
  }
}

function* handleFreezeConversation() {
  yield takeEvery(types.freezeConversation, freezeConversation);
}

function* checkForDelete({ action, data }) {
  if (action.toLowerCase === 'delete') {
    const { id } = data;
    const currentConversation = yield select(selectors.getConversationId);
    if (currentConversation === id) {
      yield put(actions.reserveNextConversation());
    }
  }
}

function* handleReceiveMessage() {
  yield takeEvery(websocketTypes.receiveMessage, checkForDelete);
}

const minTimeInSeconds = 6;
const numConversationsForTimeAnalysis = 3;
function* analyseMessageTime() {
  let timeArray = yield select(selectors.getSentMessages);
  while (true) {
    const {
      usedTime,
      message: { text },
    } = yield take(types.sendMessageSuccess);
    console.log('Times', timeArray);
    timeArray = [
      ...takeRight(timeArray, numConversationsForTimeAnalysis - 1),
      { text, time: usedTime },
    ];
    if (
      timeArray.length >= numConversationsForTimeAnalysis &&
      timeArray.every(({ time }) => time < minTimeInSeconds)
    ) {
      try {
        yield call(reportAgent, 'Moderator gesperrt wegen Spamming', { times: timeArray }, true);
        timeArray = [];
        yield put(agentActions.forceLogout());
      } catch (error) {
        console.warn(error);
      }
    }
  }
}

const minSimilarTexts = 3;
const maxComparableTexts = 10;
const minSimilarity = 0.5;
const criteria = [null, 3];
function getSimilarityBetweenTexts(text1, text2) {
  return intersection(text1, text2).length / union(text1, text2).length;
}

function chunkText(text, length) {
  return length ? chunk(text, length).map((arr) => arr.join('')) : text.split(/\s/);
}

function compareTexts(textArray) {
  const chunkedTexts = textArray.map((text) => criteria.map((length) => chunkText(text, length)));
  for (let i = 0; i <= chunkedTexts.length - minSimilarTexts; i += 1) {
    const workItem = chunkedTexts[i];
    const checkItems = chunkedTexts.slice(i + 1);
    let similarTexts = [textArray[i]];
    for (let j = 0; j < checkItems.length; j += 1) {
      const checkItem = checkItems[j];
      const grade = max(
        workItem.map((texts, index) => getSimilarityBetweenTexts(texts, checkItem[index]))
      );
      if (grade >= minSimilarity) {
        similarTexts = [...similarTexts, textArray[i + j + 1]];
        if (similarTexts.length >= minSimilarTexts) {
          return similarTexts;
        }
      }
    }
  }
  return null;
}

function* analyseMessageText() {
  let lastMessages = yield select(selectors.getLastMessagesTexts);
  while (true) {
    const {
      message: { text },
    } = yield take(types.sendMessageSuccess);
    lastMessages = [...takeRight(lastMessages, maxComparableTexts - 1), text];
    if (lastMessages.length >= minSimilarTexts) {
      const result = compareTexts(lastMessages);
      if (result) {
        try {
          yield call(
            reportAgent,
            'Verdacht auf Template-Nutzung',
            { foundMessages: result },
            false,
            true
          );
          lastMessages = [];
        } catch (error) {
          console.warn(error);
        }
      }
    }
  }
}

function* changeLanguage({ id, newLanguage }) {
  try {
    yield call(SetLanguage, id, newLanguage);
    yield put(actions.handOverSuccess());
  } catch (error) {
    yield put(actions.handOverFailure(error));
  }
}

function* handleHandOver() {
  yield takeLatest(types.handOver, changeLanguage);
}

export default {
  handleSendMessage,
  handleReserveConversation,
  reserveLoop,
  discardConversation,
  handleFreezeConversation,
  unreserveCheck,
  handleReceiveMessage,
  analyseMessageText,
  analyseMessageTime,
  handleHandOver,
};
