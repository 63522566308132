const togglePause = 'site/sessions/TOGGLEREST';
const pauseError = 'site/sessions/RESTFAILURE';
const addIn = 'site/sessions/ADDIN';
const changePage = 'site/sessions/CHANGEPAGE';
const changePageSuccess = 'site/sessions/CHANGEPAGESUCCESS';
const changePageFailure = 'site/sessions/CHANGEPAGEFAILURE';
const hasReservedOnce = 'site/sessions/HASRESERVEDONCE';

export default {
  togglePause,
  addIn,
  pauseError,
  changePage,
  changePageSuccess,
  changePageFailure,
  hasReservedOnce,
};
