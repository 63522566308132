import types from './types';

const addConversations = (conversations) => ({
  type: types.addConversations,
  conversations,
});
const removeConversation = (id) => ({ type: types.removeConversation, id });
const getConversation = (id) => ({ type: types.getConversation, id });
const getConversationSuccess = (id, messages, conversation) => ({
  type: types.getConversationSuccess,
  id,
  messages,
  conversation,
});
const getConversationFailure = (id, error) => ({
  type: types.getConversationFailure,
  id,
  error,
});
const loadMore = (id, page) => ({ type: types.loadMore, id, page });
const loadMoreSuccess = (id, messages, moreRecords) => ({
  type: types.loadMoreSuccess,
  id,
  messages,
  moreRecords,
});
const loadMoreFailure = (id, error) => ({
  type: types.loadMoreFailure,
  id,
  error,
});

const getStatistics = (id) => ({ type: types.getStatistics, id });

const getStatisticsSuccess = (id, statistics) => ({
  type: types.getStatisticsSuccess,
  id,
  statistics,
});

const getStatisticsFailure = (id, error) => ({
  type: types.getStatisticsFailure,
  id,
  error,
});

const addConversationsById = (ids) => ({
  type: types.addConversationsById,
  ids,
});

const loadConversationsFailure = (error) => ({
  type: types.loadConversationsFailure,
  error,
});

export default {
  addConversations,
  removeConversation,
  getConversation,
  getConversationSuccess,
  getConversationFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  getStatistics,
  getStatisticsSuccess,
  getStatisticsFailure,
  addConversationsById,
  loadConversationsFailure,
};
