import { eventChannel } from 'redux-saga';
import { Client } from '@stomp/stompjs';
import * as Sentry from '@sentry/browser';

function createEventChannel(url, username, password, subscriptions) {
  const client = new Client({
    brokerURL: url,
    connectHeaders: { login: username, passcode: password },
    onStompError: (errorFrame) => {
      Sentry.captureException(errorFrame);
    },
    onWebSocketError: (event) => {
      Sentry.captureException(event);
    },
    reconnectDelay: 20000,
    heartbeatIncoming: 20000,
    heartbeatOutgoing: 20000,
  });
  return {
    client,
    channel: eventChannel((emitter) => {
      client.onConnect = () => {
        subscriptions.forEach(({ type, id, url: socketUrl }) =>
          client.subscribe(socketUrl, (message) => {
            emitter({ queue: type, id, ...JSON.parse(message.body) });
          })
        );
        emitter({ type: 'Connected' });
      };
      client.activate();
      return () => {
        client.deactivate();
      };
    }),
  };
}

export default createEventChannel;
