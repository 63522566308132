import types from './types';

const addUsers = (users) => ({
  type: types.addUsers,
  users,
});

const freeze = (id, duration) => ({
  type: types.freezeUser,
  id,
  duration,
});
const freezeSuccess = (id, duration) => ({
  type: types.freezeUserSuccess,
  id,
  duration,
});
const freezeFailure = (id, error) => ({
  type: types.freezeUserFailure,
  id,
  error,
});

export default {
  addUsers,
  freeze,
  freezeSuccess,
  freezeFailure,
};
