const getAgentComponent = (state) => state.agent;

const getAgent = (state) => getAgentComponent(state).agent;

const getTeam = (state) => getAgentComponent(state).team;

const getAgency = (state) => getAgentComponent(state).agency;

const getToken = (state) => getAgentComponent(state).token;

const getLoading = (state) => getAgentComponent(state).isLoading;

const getPasswordState = (state) => getAgentComponent(state).passwordState;

const getMustChangePassword = (state) => getAgentComponent(state).mustChangePassword;

const getError = (state) => getAgentComponent(state).error;

const getAcceptedTos = (state) => getAgentComponent(state).acceptedTos;

const getAgentAlias = (state) => getAgent(state).alias;

const getLanguage = (state) => getAgentComponent(state).language;

export default {
  getAgent,
  getTeam,
  getAgency,
  getToken,
  getLoading,
  getPasswordState,
  getMustChangePassword,
  getError,
  getAcceptedTos,
  getAgentAlias,
  getLanguage,
};
