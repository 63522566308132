const sendIceBreaker = 'site/messages/SENDICEBREAKER';
const sendIceBreakerSuccess = 'site/messages/SENDICEBREAKERSUCCESS';
const sendIceBreakerFailure = 'site/messages/SENDICEBREAKERFAILURE';
const addIncomingMessage = 'site/messages/ADDINCOMINGMESSAGE';
const setPlay = 'site/messages/SETPLAY';
const reportAgent = 'site/messages/REPORT';
const reportAgentSuccess = 'site/messages/REPORTSUCCESS';
const reportAgentFailure = 'site/messages/REPORTFAILURE';

export default {
  sendIceBreaker,
  sendIceBreakerSuccess,
  sendIceBreakerFailure,
  addIncomingMessage,
  setPlay,
  reportAgent,
  reportAgentSuccess,
  reportAgentFailure,
};
