import { createSelector } from 'reselect';
import { mapValues, values, difference } from 'lodash';
import { usersSelectors } from '../users';

const getConversationsById = ({ conversations: { byId } }) => byId;

const getAllConversationIds = ({ conversations: { allIds } }) => allIds;

const getConversationsWithUsers = createSelector(
  getConversationsById,
  usersSelectors.getUsersById,
  (conversations, users) =>
    mapValues(conversations, value => ({
      ...value,
      users: value.users && value.users.map(id => users[id]),
    }))
);

const getId = (_, { id }) => id;

const makeGetConversation = () =>
  createSelector(getId, getConversationsById, (id, conversations) => conversations[id]);

const makeGetConversationStatistics = () =>
  createSelector(getId, getConversationsById, (id, conversations) =>
    conversations[id] ? conversations[id].statistics : null
  );

const makeGetConversationFromUsers = userIds =>
  createSelector(getConversationsById, byId =>
    values(byId).find(({ users }) => difference(userIds, users).length === 0)
  );

export default {
  makeGetConversation,
  getConversationsWithUsers,
  makeGetConversationStatistics,
  getAllConversationIds,
  makeGetConversationFromUsers,
};
