import { formatISO } from 'date-fns';
import types from './types';

const addEntry = (id, text, regardingClient) => ({
  type: types.addEntry,
  id,
  text,
  regardingClient,
  createdAt: formatISO(new Date()),
});
const addEntrySuccess = (id, entry) => ({ type: types.addEntrySuccess, id, entry });
const addEntryFailure = (error) => ({ type: types.addEntryFailure, error });
const getEntries = (conversationId) => ({ type: types.getEntries, conversationId });
const getEntriesSuccess = (entries) => ({ type: types.getEntriesSuccess, entries });
const getEntriesFailure = (error) => ({ type: types.getEntriesFailure, error });
const changeEntry = (id, text) => ({ type: types.changeEntry, id, text });
const changeEntrySuccess = (id, text) => ({ type: types.changeEntrySuccess, id, text });
const changeEntryFailure = (id, error) => ({ type: types.changeEntryFailure, id, error });
const editEntry = (id, edit) => ({ type: types.editEntry, id, edit });

export default {
  addEntry,
  addEntrySuccess,
  addEntryFailure,
  getEntries,
  getEntriesSuccess,
  getEntriesFailure,
  changeEntry,
  changeEntrySuccess,
  changeEntryFailure,
  editEntry,
};
