class ChannelDescription {
  id;

  queueType;

  messageType;

  constructor(id, queueType, messageType) {
    this.id = id;
    this.queueType = queueType;
    this.messageType = messageType;
  }

  toString() {
    return this.messageType
      ? `${this.queueType}=${this.id}=${this.messageType}`
      : `${this.queueType}=${this.id}`;
  }

  static fromString(string) {
    if (string) {
      const parts = string.split('=');
      if (parts.length === 3) {
        return new ChannelDescription(parts[1], parts[0], parts[2]);
      }
      return new ChannelDescription(parts[1], parts[0]);
    }
    return new ChannelDescription('', '');
  }

  setMessageType(messageType) {
    this.messageType = messageType;
    return this;
  }

  setQueueType(queueType) {
    this.queueType = queueType;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }
}

export default ChannelDescription;
