import { takeLeading, put, call, takeLatest, race, take, delay } from 'redux-saga/effects';
import Router from 'next/router';
import { differenceInSeconds } from 'date-fns';
import actions from './actions';
import { Login, ChangePassword, Logout, RemoveToken, AcceptTos } from '../services/ModerationApi';
import { configurationSelectors, getFromConfig } from '../configuration';
import types from './types';
import { sessionTypes } from '../session';

function* login({ username, password, agency }) {
  try {
    yield call(RemoveToken);
    const loginInfo = yield call(Login, { username, password, agencyShort: agency });
    yield put(actions.loginSuccess(loginInfo));
  } catch (error) {
    yield put(actions.loginFailure(error));
  }
}

function* handleLogin() {
  yield takeLeading(types.login, login);
}

function* logout({ type }) {
  try {
    yield call(Logout, type === types.forceLogout);
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
}

function* handleLogout() {
  yield takeLeading([types.logout, types.forceLogout], logout);
}

function* changePassword({ newPassword, oldPassword }) {
  try {
    yield call(ChangePassword, oldPassword, newPassword);
    yield put(actions.changePasswordSuccess());
  } catch (error) {
    yield put(actions.changePasswordFailure(error));
  }
}

function* handlePasswordChange() {
  yield takeLatest(types.changePassword, changePassword);
}

function* nextApiCall() {
  let isServerMessage = false;
  while (!isServerMessage) {
    const { type } = yield take('*');
    if (type.toLowerCase().endsWith('failure') || type.toLowerCase().endsWith('success')) {
      isServerMessage = true;
    }
  }
  return true;
}

function* checkAutomaticLogout() {
  while (true) {
    const authConfig = yield* getFromConfig(configurationSelectors.getAuth);
    const { maxTimeInactive } = authConfig;
    let lastApiTime = new Date();
    while (true) {
      const { timer, message, pause, logout: manualLogout } = yield race({
        message: call(nextApiCall),
        timer: delay(1000, true),
        pause: take(sessionTypes.togglePause),
        logout: take(types.logout),
      });
      if (pause) {
        const { type } = yield take(sessionTypes.togglePause, types.logoutSuccess);
        if (type === types.logoutSuccess) {
          break;
        }
        lastApiTime = new Date();
      }
      if (message) {
        lastApiTime = new Date();
      }
      if (timer && differenceInSeconds(new Date(), lastApiTime) > maxTimeInactive) {
        yield put(actions.forceLogout());
        yield take([types.logoutSuccess, types.logoutFailure]);
        break;
      }
      if (manualLogout) {
        yield take([types.logoutSuccess, types.logoutFailure]);
        break;
      }
    }
  }
}

function* checkLogoutInPause() {
  while (true) {
    const authConfig = yield* getFromConfig(configurationSelectors.getAuth);
    const { maxTimePause } = authConfig;
    yield take(sessionTypes.togglePause);
    const pauseTime = new Date();
    while (true) {
      const { timer, pauseEnd, logout } = yield race({
        timer: delay(1000, true),
        pauseEnd: take(sessionTypes.togglePause),
        logout: take(types.logout),
      });
      if (pauseEnd || logout) {
        break;
      } else if (timer && differenceInSeconds(new Date(), pauseTime) > maxTimePause) {
        yield put(actions.forceLogout());
        break;
      }
    }
  }
}

function* handleRedirectLogin() {
  while (true) {
    yield take(types.logout);
    const { type } = yield take([types.logoutSuccess, types.logoutFailure]);
    if (type === types.logoutSuccess) {
      Router.push('/');
    }
  }
}

function* handleForcedLogout() {
  while (true) {
    yield take(types.forceLogout);
    const { type } = yield take([types.logoutSuccess, types.logoutFailure]);
    if (type === types.logoutSuccess) {
      Router.push('/?loggedOut=true');
    }
  }
}

function* acceptTosAsUser() {
  try {
    yield call(AcceptTos);
    yield put(actions.acceptTosSuccess());
  } catch (error) {
    yield put(actions.acceptTosFailure(error));
  }
}

function* handleAcceptTos() {
  yield takeLeading(types.acceptTos, acceptTosAsUser);
}

export default {
  handleLogin,
  handlePasswordChange,
  handleLogout,
  checkAutomaticLogout,
  checkLogoutInPause,
  handleRedirectLogin,
  handleAcceptTos,
  handleForcedLogout,
};
