export default {
  api: {
    uri:
      process.env.NODE_ENV !== 'production'
        ? 'https://3-71-241-226.nip.io/api/'
        : 'https://api.chocolate-life.com/api/',
  },
  dev: true,
  dateTime: {
    format: 'D.MMM YYYY | LT [Uhr]',
  },
  maps: {
    api: 'AIzaSyB4bCw5Nuc-8xGTK0GngKOS_nfgi87oZK8',
  },
};