import { formatISO } from 'date-fns';
import types from './types';

const add = (conversation, reason, queueName) => ({
  type: types.add,
  conversation,
  reason,
  queueName,
});
const addSuccess = (id, reason, queueName, timeZone, language) => ({
  type: types.addSuccess,
  id,
  reason,
  queueName,
  timeZone,
  language,
  inRestroomSince: formatISO(new Date()),
});
const addIncoming = (conversation, reason, queueName, timeZone, language) => ({
  type: types.addIncoming,
  conversation,
  reason,
  queueName,
  timeZone,
  language,
  inRestroomSince: formatISO(new Date()),
});
const addFailure = (id, error) => ({ type: types.addFailure, id, error });
const remove = (id) => ({ type: types.remove, id });
const getRestroom = () => ({ type: types.getRestroom });
const getRestroomSuccess = (conversations) => ({ type: types.getRestroomSuccess, conversations });
const getRestroomFailure = (error) => ({ type: types.getRestroomFailure, error });
const flushSuccess = (ids) => ({ type: types.flushSuccess, ids });
const flushFailure = (error) => ({ type: types.flushFailure, error });

export default {
  add,
  addSuccess,
  addFailure,
  addIncoming,
  remove,
  getRestroom,
  getRestroomSuccess,
  getRestroomFailure,
  flushSuccess,
  flushFailure,
};
