import axios from 'axios';
import { entries, mapValues, flatten, each, isString } from 'lodash';
import Config from '../Config';
import ChannelInformation from '../ChannelInformation';
import ChannelDescription from '../ChannelDescription';

const serviceClient = axios.create({
  baseURL: Config.api.uri,
  headers: { 'x-client-name': 'moderation' },
});

serviceClient.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(response);
    }
    return response;
  },
  (error) => {
    console.log(error);
    const errorValue = error.response || error;
    return Promise.reject(errorValue);
  }
);

export function SetToken(token) {
  serviceClient.defaults.headers.Authorization = `Bearer ${token}`;
}

export function RemoveToken() {
  delete serviceClient.defaults.headers.Authorization;
}

export function Login({ username, password, agencyShort }) {
  return serviceClient
    .post('v2/moderation/auth/login', {
      username,
      password,
      agencyShort,
      'scope[]': '*',
    })
    .then(
      ({
        data: {
          items: {
            accessToken: token,
            agency,
            team,
            moderator: { name, id, camouflage_name: alias, language },
            needPasswordChange,
            needAcceptTos,
          },
        },
      }) => {
        SetToken(token);
        return {
          agency,
          team,
          agent: {
            name,
            id,
            alias,
          },
          token,
          language,
          mustChangePassword: needPasswordChange,
          acceptedTos: !needAcceptTos,
        };
      }
    );
}

export function Logout(force = false) {
  return serviceClient
    .get('v2/moderation/auth/logout', { params: { force } })
    .then(({ data: { statusCode } }) => {
      const success = statusCode === 200;
      if (success) RemoveToken();
      return success;
    });
}

export const Pause = (stopped) =>
  serviceClient.get('v2/moderation/auth/pause', { params: { stopped } });

const transformTree = ({ value, children, ...entries }) => {
  if (typeof value !== 'undefined') {
    return value;
  }
  if (children) {
    return transformTree(children);
  }
  return mapValues(entries, (val) => transformTree(val));
};

export function GetConfig() {
  return serviceClient
    .get('v2/configurations')
    .then(({ data: { items } }) => {
      const tree = transformTree(items);
      //console.log(tree);
      return tree;
    })
    .then(
      ({
        settings: {
          auth: {
            max_inactive_time_seconds: maxTimeInactive,
            logout_pause_after_seconds: maxTimePause,
            current_place_timer_seconds: sendPageTimer,
          },
          messaging: {
            asa: asaConfig,
            min_chars: minCharsDefault,
            max_chars: maxChars,
            max_answer_time_seconds: maxTime,
            valid_asa_levels: validAsaLevel,
            can_skip_dialogs: canTravel,
            can_move_dialogs: canChangeLanguage,
          },
          amazon_mq: {
            message_types: messageTypes,
            socket_client: url,
            message_order: workOrder,
            channels_compress: compressChannel,
            username,
            password,
            channels,
          },
          visits: { min_secs_between_next_visit: timeBlocked },
          statements: { active },
        },
        global: { languages },
      }) => ({
        messages: {
          minChars: {
            default: minCharsDefault,
            ...mapValues(asaConfig, 'min_chars'),
          },
          maxChars,
          maxTime,
        },
        workOrder: workOrder.map(({ queue: messageType, type: queueType, guid: id }) => {
          return {
            name: new ChannelDescription(id, queueType, messageType).toString(),
            compress:
              compressChannel[messageType] &&
              !!compressChannel[messageType].find((item) => `${messageType}_${queueType}` === item),
          };
        }),
        asa: {
          isFiltered: Array.isArray(validAsaLevel),
          validLevel: [0, ...(validAsaLevel || [])],
        },
        connections: {
          messageTypes,
          url,
          username,
          password,
          channels: flatten(
            entries(channels).map(([id, subscriptions]) =>
              entries(subscriptions).map(([type, { url: subUrl }]) =>
                new ChannelInformation(type, id, subUrl).toValue()
              )
            )
          ),
        },
        auth: {
          maxTimeInactive,
          maxTimePause,
          sendPageTimer,
        },
        privileges: {
          canTravel,
        },
        visit: { timeBlocked },
        availableStatements: active,
        languages,
        canChangeLanguage,
      })
    );
}

export const GetGallery = (userId, asUserId) =>
  serviceClient
    .get(`v2/moderation/media/list/${userId}`, { params: { asUserId } })
    .then(({ data: { items: { images, countImages } } }) => ({
      count: countImages,
      gallery: images.map(
        ({
          guid: id,
          filename: name,
          filepath: path,
          visible,
          isBillable,
          costUnlock: price,
          unlockedForUser: isUnlocked,
          rewardByUnlock: unlockReward,
          adult,
        }) => ({
          id,
          url: path + name,
          visible: visible === 1,
          price,
          isBillable,
          isUnlocked,
          unlockReward,
          adult,
        })
      ),
    }));

export const UnlockImage = (mediaId, userId) =>
  serviceClient
    .post('v2/moderation/media/unlock', { mediaId, userId })
    .then(({ data: { items: url } }) => url);

export function GetQueue(messageType, queueType, id) {
  return serviceClient
    .get('v3/moderation/messaging/queues', {
      params: {
        type: messageType,
        contained: queueType,
        guid: id,
      },
    })
    .then(({ data: { statusCode, items } }) => {
      if (statusCode === 200) {
        return {
          conversations: items[queueType].map(
            ({ conversationHash: id, updatedAt: inQueueSince, asaLevel, timeZone, language }) => ({
              id,
              inQueueSince,
              level: asaLevel || 0,
              timeZone,
              language,
            })
          ),
        };
      }
      return null;
    });
}

export const ReserveConversation = (id, canWrite, fromOutOfQueue) =>
  serviceClient.get(`v2/moderation/messaging/conversation/reserve/${id}`, {
    params: { inWriteModus: canWrite ? 1 : 0, fromOutOfQueue },
  });

export const DiscardConversation = (id) =>
  serviceClient.get(`v2/moderation/messaging/conversation/unreserve/${id}`);

export const CreateRestroom = (id, reason) =>
  serviceClient.post('v2/moderation/conversationrestrooms/create', {
    id,
    reason,
  });

export const AssignFromRestroom = (id) =>
  serviceClient.put('v2/moderation/restroom/update', { id, assign: 1 });

export const FlushFromRestroom = (ids) =>
  serviceClient.post('v2/moderation/restroom/flush', { items: ids });

export const GetProfile = (id, conversationId) =>
  serviceClient
    .get(`v2/moderation/user/${id}/profile`, { params: { conversationId } })
    .then(({ data: { items } }) => items);

export const GetUserDialogInfo = (conversationId, userId) =>
  serviceClient.get(`v2/moderation/dialoginfos/${conversationId}`, { params: { userId } }).then(
    ({
      data: {
        items: { dialoginfo },
      },
    }) => dialoginfo
  );

export const UpdateUserDialogInfo = (conversationId, userId, entry) =>
  serviceClient.put(`v2/moderation/dialoginfos/${conversationId}`, {
    userId,
    dialoginfo: entry,
  });

export const GetUserInfo = (userId) =>
  serviceClient
    .get('v2/moderation/notices/list', {
      params: { type: 'user', extId: userId },
    })
    .then(({ data: { items } }) => (items.length !== 0 ? items[0].message : ''));

export const UpdateUserInfo = (userId, text) =>
  serviceClient.post('v2/moderation/notices/store', {
    type: 'user',
    extId: userId,
    message: text,
  });

export const GetDialogInfos = (conversationId) =>
  serviceClient
    .get('v2/moderation/notices/list', {
      params: { type: 'conversation', extId: conversationId },
    })
    .then(({ data: { items } }) =>
      items.map(
        ({
          id,
          message: text,
          createdAt,
          agent_id: agentId,
          agent_name: agentName,
          reference,
        }) => ({
          id,
          createdAt,
          text,
          regardingClient: reference === 0 || reference === 1 ? reference === 1 : undefined,
          agent: { name: agentName, id: agentId },
        })
      )
    );

export const CreateDialogInfo = (conversationId, text, regardingClient) =>
  serviceClient
    .post('v2/moderation/notices/store', {
      type: 'conversation',
      extId: conversationId,
      message: text,
      reference: regardingClient ? 1 : 0,
    })
    .then(
      ({
        data: {
          items: { id, message, createdAt, agent_id: agentId, agent_name: agentName, reference },
        },
      }) => ({
        id,
        createdAt,
        text: message,
        regardingClient: reference === 1,
        agent: { name: agentName, id: agentId },
      })
    );

export const UpdateDialogInfo = (id, text) =>
  serviceClient.put('v2/moderation/notices/update', { id, message: text });

export function ShowConversation(id, page = 1) {
  return serviceClient
    .get(
      `v2/moderation/messaging/conversations/${id}?include=messages:sortBy(created_at):sortOrder(desc):perPage(10):currentPage(${page})`
    )
    .then(
      ({
        data: {
          items: {
            modCity,
            modCountryS,
            binded_lat: lat,
            binded_lng: lng,
            visitDate,
            distance,
            firstMessageDate: firstContact,
            messages,
            canSendIceBreaker,
            last_freeze_until: frozenUntil,
            last_freeze_reason: frozenReason,
            last_freeze_from: frozenFrom,
            last_freeze_show_alert: showFreezeAlert,
            pagination: { moreItems: moreRecords },
            statements,
          },
        },
      }) => ({
        messages: messages.map(
          ({
            id: messageId,
            text,
            preview,
            MessageType: messageType,
            created_at: createdAt,
            deleted_at: deletedAt,
            agentName,
            agent_id: agentId,
            user_guid: userId,
          }) => ({
            id: messageId,
            text,
            agentName,
            agentId,
            preview,
            messageType,
            createdAt,
            isDeleted: !!deletedAt,
            userId,
            distance,
            conversationId: id,
          })
        ),
        conversation: {
          modLocation: { city: modCity, countryShort: modCountryS, lat, lng },
          distance,
          moreRecords,
          canSendIceBreaker,
          firstContact,
          visitDate,
          statements,
          frozen: showFreezeAlert
            ? { until: frozenUntil, from: frozenFrom, reason: frozenReason }
            : null,
        },
      })
    );
}

export function GetConversationWithUsers(id) {
  return serviceClient
    .get(
      `v2/moderation/messaging/conversations/${id}?include=messages:sortBy(created_at):sortOrder(desc):perPage(10):currentPage(1)&userInfo=1`
    )
    .then(
      ({
        data: {
          items: {
            modCity,
            modCountryS,
            binded_lat: lat,
            binded_lng: lng,
            visitDate,
            distance,
            firstMessageDate: firstContact,
            messages,
            canSendIceBreaker,
            pagination: { moreItems: moreRecords },
            statements,
            users,
            moderatedUser,
            timeZone,
            language,
          },
        },
      }) => ({
        messages: messages.map(
          ({
            id: messageId,
            text,
            preview,
            MessageType: messageType,
            created_at: createdAt,
            deleted_at: deletedAt,
            agentName,
            agent_id: agentId,
            user_guid: userId,
          }) => ({
            id: messageId,
            text,
            agentId,
            agentName: agentName ?? null,
            preview: preview ?? null,
            messageType,
            createdAt,
            isDeleted: !!deletedAt,
            userId,
            distance,
            conversationId: id,
          })
        ),
        conversation: {
          id,
          modLocation: { city: modCity, countryShort: modCountryS, lat, lng },
          distance,
          moreRecords,
          canSendIceBreaker,
          firstContact,
          visitDate,
          statements,
          frozen: null,
          users,
          moderatedUser,
          timeZone,
          language,
        },
      })
    );
}

export function CreateMessage({
  conversationId,
  messageType,
  message,
  voice,
  duration,
  hold = false,
  freezeDuration,
  reason,
}) {
  return serviceClient
    .post('v2/moderation/messaging/messages', {
      conversation_id: conversationId,
      messageType,
      message,
      voice,
      duration,
      hold,
      freeze_duration: freezeDuration,
      freeze_reason: reason,
    })
    .then(
      ({
        data: {
          items: {
            agentName,
            text,
            id: messageId,
            created_at: createdAt,
            preview,
            MessageType,
            user_guid: userId,
          },
        },
      }) => ({
        message: {
          id: messageId,
          conversationId,
          text,
          preview,
          createdAt,
          messageType: MessageType,
          userId,
          agentName,
        },
      })
    );
}

export const GetRestroom = () =>
  serviceClient.get('v2/moderation/restroom/list').then(({ data: { items } }) =>
    items.map(
      ({
        conversationHash: id,
        inRestroomSince,
        user,
        reason,
        channelType: queueType,
        messageType,
        moderatedUser,
        timeZone,
        guid: channelId,
        language,
      }) => ({
        id,
        inRestroomSince,
        moderatedUser,
        queueName: new ChannelDescription(channelId, queueType, messageType).toString(),
        reason,
        timeZone,
        language,
        users: user.map(({ guid: userId, firstname: name, profileImage: image, gender }) => ({
          id: userId,
          name,
          image,
          gender,
        })),
      })
    )
  );

export const GetNews = () =>
  serviceClient
    .get('v2/moderation/news/list')
    .then(({ data: { items: { team: teamNews, global: agencyNews } } }) => ({
      teamNews,
      agencyNews,
    }))
    .then(x => {
      try{
        each(x, i => each(i, an => each(an, (v,k) => an[k] = isString(v) ? v.split("\\n").join("\n") : v)));
      }
      catch(e){
        console.log("transformationerror of news", e);
      }
      return x
    })

export const ToRestroom = (id, reason) =>
  serviceClient.post('v2/moderation/restroom/create', { id, reason });

export const ChangePassword = (oldPassword, newPassword) =>
  serviceClient.put('v2/moderation/auth/changepassword', {
    old_password: oldPassword,
    new_password: newPassword,
  });

export const Freeze = ({ id, duration, reason }) =>
  serviceClient.post(`v2/moderation/messaging/conversation/freeze/${id}`, {
    duration,
    reason,
  });

export const GetPresents = () =>
  serviceClient
    .get('v2/moderation/gifts/list')
    .then(({ data: { items } }) =>
      items.map(({ key: id, price, image }) => ({ id, price, image }))
    );

export const SendPresent = (key, fromUserId, toUserId) =>
  serviceClient
    .post('v2/moderation/gifts/send', { key, toUserId, fromUserId })
    .then(
      ({
        data: {
          items: {
            text,
            user_guid: userId,
            created_at: createdAt,
            id,
            MessageType: messageType,
            preview,
            conversation: { hash_id: conversationId },
            agentName,
          },
        },
      }) => ({
        message: {
          text,
          userId,
          createdAt,
          id,
          messageType,
          preview,
          conversationId,
          agentName,
        },
      })
    );

export const GetCoins = (userId) =>
  serviceClient.get(`v2/moderation/user/${userId}/coins`).then(
    ({
      data: {
        items: { coinsSum },
      },
    }) => coinsSum
  );

export const ReportImage = (id, reason, flags) =>
  serviceClient.post(`v2/moderation/report/content`, {
    type: 'media',
    id,
    flags,
    freetext: reason,
  });

export const GetFriendshipStatus = (toUser, fromUser) =>
  serviceClient
    .get(`v2/moderation/friends/status/${toUser}/${fromUser}`)
    .then(({ data: { items: { status, sender, createdAt } } }) => ({
      status,
      isMyRequest: sender === fromUser,
      createdAt,
    }));
export const SendFriendRequest = (toUser, fromUser) =>
  serviceClient.get(`v2/moderation/friends/befriend/${toUser}/${fromUser}`);

export const AcceptFriendRequest = (toUser, fromUser) =>
  serviceClient.get(`v2/moderation/friends/accept/${toUser}/${fromUser}`);

export const DenyFriendRequest = (toUser, fromUser) =>
  serviceClient.get(`v2/moderation/friends/deny/${toUser}/${fromUser}`);

export const GetOnlineStatus = (user) =>
  serviceClient
    .get(`v2/moderation/user/online/${user}`)
    .then(({ data: { items: { isOnline, createdAt, platform } } }) => ({
      lastOnline: !isOnline && createdAt,
      platform,
    }));

export const GetConversationStatistics = (conversationId) =>
  serviceClient
    .get(`v1/moderation/statistics/conversationinout/${conversationId}`)
    .then(
      ({
        data: {
          items: { ins_agent_all: inMessages = 0, outs_agent_all: outMessages = 0 },
        },
      }) => ({
        inMessages,
        outMessages,
      })
    );

export const SendCurrentPage = (pageName) =>
  serviceClient.post('v2/moderation/organisation/agents/current_place', {
    place: pageName,
  });

export const GetStatistics = (from, to) =>
  serviceClient
    .get('v1/moderation/statistics/agent_range', { params: { from, to } })
    .then(
      ({
        data: {
          items: {
            workingSeconds: workTime,
            pauseSeconds: pause,
            in: inValue,
            out: outValue,
            avgDurationSeconds: meanAnswerTime,
            autoLogouts,
            effectiveWorkingSeconds: effectiveWorkTime,
            earnings,
          },
        },
      }) => ({
        workTime,
        pause,
        inValue,
        outValue,
        autoLogouts,
        meanAnswerTime,
        effectiveWorkTime,
        earnings,
      })
    );

export const GetDocumentList = () =>
  serviceClient.get('v2/moderation/documents/list').then(({ data: { items } }) => items);

export const AcceptTos = () => serviceClient.post('v2/moderation/auth/tos', { tosAccepted: 1 });

export const GetConversationsById = (ids) =>
  serviceClient
    .get(`v3/moderation/messaging/queueinformations/?ids=${ids.join(',')}`)
    .then(({ data: { items } }) =>
      items.map(({ conversationHash, user, moderatedUser }) => ({
        id: conversationHash,
        users: user.map(({ guid, profilePicture, firstname: name, gender }) => ({
          id: guid,
          image: profilePicture,
          name,
          gender,
        })),
        moderatedUser,
      }))
    );

export const reportAgent = (reason, params, lock = false, templateDetection = false) =>
  serviceClient.post(
    `v2/moderation/report/agent`,
    { reason, ...params },
    {
      params: {
        lock: lock ? 1 : undefined,
        templatedetection: templateDetection ? 1 : undefined,
      },
    }
  );

export const simulateVisit = (id, asUser) =>
  serviceClient.get(`v2/moderation/user/${asUser}/visit/${id}`);

export const reportClient = (id, conversationId, reason, flags) =>
  serviceClient.post('v2/moderation/report/content', {
    type: 'user',
    id,
    freetext: reason,
    flags,
    conversation_id: conversationId,
  });

export const reportAgentForMessage = (agent, message, conversation, reason, flags) =>
  serviceClient.post('v2/moderation/report/content', {
    type: 'agent',
    id: agent,
    message_id: message,
    conversation_id: conversation,
    freetext: reason,
    flags,
  });

export const setAdultOnImage = (id, value) =>
  serviceClient.put(`v2/moderation/media/update/${id}`, { adult: value });

export const UpdateProfile = (id, profile) =>
  serviceClient.put(`v2/moderation/user/${id}/update`, profile);

export const ReportFailure = (path, data) =>
  serviceClient.post('app/apprequestlog', { path, log_data: data });

export const SetStatements = (id, statement) =>
  serviceClient.put(`v2/moderation/user/${id}/update`, { statement });

export const SetLanguage = (id, language) =>
  serviceClient.post('v2/moderation/messaging/conversation/move', {
    id,
    language,
  });

export const GetServiceLogin = (token) =>
  serviceClient
    .get('/admincenter/auth/modlogin', { headers: { Authorization: `Bearer ${token}` } })
    .then(
      ({
        data: {
          items: {
            accessToken: token,
            agency,
            team,
            moderator: { name, id, camouflage_name: alias, language },
          },
        },
      }) => {
        SetToken(token);
        return {
          agency,
          team,
          agent: {
            name,
            id,
            alias,
          },
          token,
          language,
          mustChangePassword: false,
          acceptedTos: false,
        };
      }
    );

export const GetDocuments = (language) =>
  serviceClient
    .get('/v2/moderation/documents/md', { params: { lang: language } })
    .then(({ data: { items } }) =>
      items.map(({ version, context: { signifier: id }, content }) => ({ version, id, content }))
    );

export default serviceClient;
