import { put, call, select, take, race, delay, takeEvery } from 'redux-saga/effects';
import { differenceInSeconds, parseISO } from 'date-fns';
import types from './types';
import actions from './actions';
import selectors from './selectors';
import { ReserveConversation, DiscardConversation } from '../services/ModerationApi';
import { reservedConversationTypes } from '../reservedConversation';
import { queuesSelectors } from '../queues';
import { getFromConfig, configurationSelectors } from '../configuration';
import { agentTypes } from '../agent';
import { conversationActions } from '../conversations';
import { conversationInformationEntriesActions } from '../conversationInformationEntries';
import { websocketActions } from '../websocket';

const maxPrereserved = 1;

function* handleReservation() {
  while (true) {
    yield take([
      reservedConversationTypes.reserveConversationSuccess,
      types.prereserveConversationSuccess,
      types.discardReservedConversationSuccess,
      reservedConversationTypes.takePrereservedConversation,
    ]);
    const count = yield select(selectors.getPrereservedCount);
    if (count < maxPrereserved) {
      const queueItem = yield select(queuesSelectors.getNextViableReservation);
      if (queueItem) {
        const { id, queueName, level, timeZone, language } = queueItem;
        yield put(actions.prereserveConversation(id, queueName, level, timeZone, language));
      }
    }
  }
}

function* checkForDiscard() {
  while (true) {
    const messageConfig = yield* getFromConfig(configurationSelectors.getMessageConfig);
    const { maxTime } = messageConfig;
    if (maxTime) {
      while (true) {
        const { logout } = yield race({
          time: delay(1000),
          logout: take(agentTypes.logoutSuccess),
        });
        if (logout) {
          break;
        }
        const firstInOrder = yield select(selectors.getFirstPrereservedConversation);
        if (
          firstInOrder &&
          differenceInSeconds(new Date(), parseISO(firstInOrder.reserveTime)) > maxTime
        ) {
          yield put(actions.discardReservedConversation(firstInOrder.id));
        }
      }
    }
  }
}

function* reserveForQueue({ id, queueName, asaLevel, timeZone, language }) {
  yield put(websocketActions.sendMessage(queueName, 'reserve', { id }));
  try {
    yield call(ReserveConversation, id);
    yield put(actions.prereserveConversationSuccess(id, queueName, asaLevel, timeZone, language));
  } catch (error) {
    yield put(actions.prereserveConversationFailure(error));
  }
}

function* handlePrereserve() {
  yield takeEvery(types.prereserveConversation, reserveForQueue);
}

function* discardFoulConversations({ id }) {
  try {
    yield call(DiscardConversation, id);
    yield put(actions.discardReservedConversationSuccess(id));
  } catch (error) {
    yield put(actions.discardReservedConversationFailure(error, id));
  }
}

function* handleDiscard() {
  yield takeEvery(types.discardReservedConversation, discardFoulConversations);
}

function* onTake({ id }) {
  yield call(ReserveConversation, id, true);
}

function* handleTake() {
  yield takeEvery(reservedConversationTypes.takePrereservedConversation, onTake);
}

function* preloadInformation({ id }) {
  yield put(conversationActions.getConversation(id));
  yield put(conversationInformationEntriesActions.getEntries(id));
  yield put(conversationActions.getStatistics(id));
}

function* handlePrereserveSuccess() {
  yield takeEvery(types.prereserveConversationSuccess, preloadInformation);
}

export default {
  handleDiscard,
  handlePrereserve,
  handleReservation,
  checkForDiscard,
  handleTake,
  handlePrereserveSuccess,
};
