import { combineReducers } from 'redux';
import { uniq, keyBy } from 'lodash';
import types from './types';
import websocketTypes from '../websocket/types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.restroom:
    case websocketTypes.create: {
      const {
        conversation: { users },
      } = action;
      return uniq([...state, ...users.map(({ id }) => id)]);
    }
    case types.addUsers: {
      const { users } = action;
      return uniq([...state, ...users.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case websocketTypes.restroom:
    case websocketTypes.create: {
      const {
        conversation: { users },
      } = action;
      return {
        ...state,
        ...keyBy(
          users.map((user) => Object.assign({}, state[user.id], user)),
          'id'
        ),
      };
    }
    case types.addUsers: {
      const { users } = action;
      return {
        ...state,
        ...keyBy(
          users.map((user) => Object.assign({}, state[user.id], user)),
          'id'
        ),
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ allIds: allIdsReducer, byId: byIdReducer });
