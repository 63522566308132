import { formatISO } from 'date-fns';
import types from './types';

const login = (agency, username, password) => ({
  type: types.login,
  agency,
  username,
  password,
});
const loginSuccess = ({
  agency,
  agent,
  team,
  token,
  mustChangePassword,
  acceptedTos,
  language,
}) => ({
  type: types.loginSuccess,
  agency,
  agent,
  team,
  token,
  mustChangePassword,
  acceptedTos,
  language,
  startTime: formatISO(new Date()),
});
const loginFailure = (error) => ({ type: types.loginFailure, error });
const logout = () => ({ type: types.logout });
const forceLogout = () => ({ type: types.forceLogout });
const logoutSuccess = () => ({ type: types.logoutSuccess });
const logoutFailure = (error) => ({ type: types.logoutFailure, error });
const changePassword = (oldPassword, newPassword) => ({
  type: types.changePassword,
  oldPassword,
  newPassword,
});
const changePasswordSuccess = () => ({ type: types.changePasswordSuccess });
const changePasswordFailure = (error) => ({ type: types.changePasswordFailure, error });
const acceptTos = () => ({ type: types.acceptTos });
const acceptTosSuccess = () => ({ type: types.acceptTosSuccess });
const acceptTosFailure = (error) => ({ type: types.acceptTosFailure, error });

export default {
  login,
  loginSuccess,
  loginFailure,
  logout,
  forceLogout,
  logoutSuccess,
  logoutFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  acceptTos,
  acceptTosSuccess,
  acceptTosFailure,
};
