const createQueues = 'site/queues/CREATE';
const setOrder = 'site/queues/ORDER';
const retrieveQueues = 'site/queues/RETRIEVE';
const retrieveQueuesSuccess = 'site/queues/RETRIEVESUCCESS';
const retrieveQueuesFailure = 'site/queues/RETRIEVEFAILURE';
const addToQueue = 'site/queues/ADD';
const removeFromQueue = 'site/queues/REMOVE';
const loaded = 'site/queues/LOADED';
const flush = 'site/queues/FLUSH';
const resetConversationLoad = 'site/queues/RESETLOAD';
const load = 'site/queues/load';

export default {
  createQueues,
  addToQueue,
  removeFromQueue,
  retrieveQueues,
  retrieveQueuesSuccess,
  retrieveQueuesFailure,
  setOrder,
  loaded,
  flush,
  resetConversationLoad,
  load,
};
