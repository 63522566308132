import { createSelector } from 'reselect';
import { sortBy, mapValues, takeWhile, difference } from 'lodash';
import { parseISO } from 'date-fns';
import { conversationSelectors } from '../conversations';
import { configurationSelectors } from '../configuration';
import ChannelDescription from '../ChannelDescription';

const getQueues = (state) => state.queues;

const getQueuesById = (state) => getQueues(state).byId;

const getQueueIds = (state) => getQueues(state).allIds;

const getLoaded = (state) => getQueues(state).loaded;

const getFilteredQueuesById = createSelector(
  getQueuesById,
  configurationSelectors.getAsaConfig,
  (byId, asaConfig) =>
    asaConfig.isFiltered
      ? mapValues(byId, (value) => ({
          ...value,
          items: value.items.filter(({ level }) =>
            asaConfig.validLevel.some((validLevel) => level === validLevel)
          ),
        }))
      : byId
);

const getSortedQueuesById = createSelector(getFilteredQueuesById, (queues) =>
  mapValues(queues, (value, key) => ({
    ...value,
    items: createSortedQueueItems(
      value.items.map((item) => ({
        ...item,
        queueName: key,
      }))
    ),
  }))
);

const createSortedQueueItems = (array) =>
  sortBy(array, ['level', ({ inQueueSince }) => parseISO(inQueueSince).getTime()]);

const getMessageCount = (messageType, queueType) =>
  createSelector(getQueueIds, getFilteredQueuesById, (ids, byId) =>
    ids.reduce(
      (i, id) =>
        ChannelDescription.fromString(id).messageType === messageType &&
        (!queueType || ChannelDescription.fromString(id).queueType === queueType)
          ? byId[id].items.length + i
          : i,
      0
    )
  );

const getSortedItemsInOrder = createSelector(
  getSortedQueuesById,
  configurationSelectors.getWorkOrder,
  (queues, order) =>
    order
      ? order.reduce(
          ({ skip, list }, { name, compress = false }, index) => {
            if (skip !== 0) {
              return { list, skip: skip - 1 };
            }
            if (!queues[name]) {
              return { list, skip };
            }
            if (!compress) {
              return { list: [...list, ...queues[name].items], skip: 0 };
            }
            let i = 1;
            let cumulativeItems = queues[name].items;
            while (order[index + i] && order[index + i].compress) {
              const { name: furtherName } = order[index + i];

              cumulativeItems = queues[furtherName]
                ? [...cumulativeItems, ...queues[furtherName].items]
                : cumulativeItems;
              i += 1;
            }
            const sortedItems = createSortedQueueItems([...cumulativeItems]);
            return { list: [...list, ...sortedItems], skip: i - 1 };
          },
          { skip: 0, list: [] }
        ).list
      : []
);

const getQueuedConversationsIds = createSelector(
  getSortedItemsInOrder,
  conversationSelectors.getAllConversationIds,
  (items, ids) => takeWhile(items, (item) => ids.some((id) => id === item.id)).map(({ id }) => id)
);

const getQueuedConversations = createSelector(
  getSortedItemsInOrder,
  conversationSelectors.getConversationsWithUsers,
  conversationSelectors.getAllConversationIds,
  (ids, conversations, conversationIds) =>
    takeWhile(ids, (item) => conversationIds.some((id) => id === item.id)).map((item) => ({
      ...conversations[item.id],
      ...item,
    }))
);

const getIdsWithoutConversation = createSelector(
  getSortedItemsInOrder,
  conversationSelectors.getAllConversationIds,
  (items, ids) =>
    difference(
      items.map(({ id }) => id),
      ids
    )
);

const getQueuesEmpty = createSelector(getSortedItemsInOrder, (array) => array.length < 1);

const getNextViableReservation = createSelector(getQueuedConversations, (array) =>
  array.length > 0 ? array[0] : null
);

const getHasViableNextConversation = createSelector(
  getQueuedConversations,
  (array) => array.length > 0
);

const getQueuesLoaded = createSelector(
  getQueueIds,
  getQueuesById,
  getLoaded,
  (ids, items, loaded) =>
    (ids.length > 0 && ids.reduce((bool, id) => items[id].loaded && bool, true)) || loaded
);

export default {
  getQueuedConversations,
  getNextViableReservation,
  getMessageCount,
  getQueuesEmpty,
  getQueuesLoaded,
  getQueuedConversationsIds,
  getIdsWithoutConversation,
  getHasViableNextConversation,
};
