import { createSelector } from 'reselect';
import { sortBy, filter, findIndex } from 'lodash';
import { parseISO } from 'date-fns';
import { conversationSelectors } from '../conversations';

const getRestRoomById = ({ restroom: { byId } }) => byId;

const getRestRoomIds = ({ restroom: { allIds } }) => allIds;

const getAllInRestRoom = createSelector(
  getRestRoomById,
  getRestRoomIds,
  conversationSelectors.getConversationsWithUsers,
  (byId, ids, conversations) => {
    let x = sortBy(
      ids.map((id) => ({ ...conversations[id], ...byId[id] })),
      ({ inRestroomSince }) => parseISO(inRestroomSince).getTime()
    );
    x = filter(x, (conv) => !(conv.users.length != 2 || findIndex(conv.users, u => u == undefined || u == null) != -1));
    return x
  }
);

const makeGetItemsFromQueue = (queueName) =>
  createSelector(getRestRoomIds, getRestRoomById, (ids, items) =>
    ids.reduce((arr, id) => {
      const item = items[id];
      const { queueName: itemQueueName } = item || {};
      return item && itemQueueName === queueName ? [...arr, item] : arr;
    }, [])
  );

const getRestRoomCount = createSelector(getRestRoomIds, (ids) => ids.length);

export default { getAllInRestRoom, getRestRoomCount, makeGetItemsFromQueue };
